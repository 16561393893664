import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  BOOK_QUEUE_WITH_BRANCH,
  CANCEL_QUEUE,
  FETCH_QUEUE,
  ORDER_FOOD,
} from '../../utils/graphql'
import { CloseOutlined } from '@ant-design/icons'
import { css, Global } from '@emotion/core'
import liffHelper from '../../utils/liffHelper'

const CustomModal = (props: any) => {
  // const [fetchQueue, { loading: fetchQueueLoading }] = useMutation(FETCH_QUEUE);
  const [cancelQueue, { loading: cancelQueueLoading }] =
    useMutation(CANCEL_QUEUE)
  const [orderFood, { loading: orderFoodLoading }] = useMutation(ORDER_FOOD)
  const [bookQueue, { loading: bookQueueLoading }] = useMutation(
    BOOK_QUEUE_WITH_BRANCH,
    {
      onCompleted: (sre: any) => {},
      onError: (err) => {
        window.alert(JSON.stringify(err))
        // window.alert(userId + 'GET_QUEUES' + err)
      },
    }
  )

  const insistEvent = async () => {
    try {
      if (props && props.type === 'fetch') {
        // await fetchQueue({ variables: { id: props.id } })
        await props.onCancel()
      } else if (props && props.type === 'cancel') {
        if (!cancelQueueLoading) {
          await cancelQueue({
            variables: {
              id: parseInt(props.id),
              cancelled_by_employee: props.cancelled_by_employee,
            },
          })
          if (props.onCancel) {
            await props.onCancel()
          }
        }
      } else if (props && props.type === 'order') {
        if (!orderFoodLoading) {
          await orderFood({ variables: { id: parseInt(props.id) } })
        }
      } else if (props && props.type === 'book') {
        if (!bookQueueLoading) {
          // window.alert(
          //   JSON.stringify({
          //     seat: props.seat,
          //     name: props.name,
          //     userId: props.userId,
          //     branchId: props.branchId,
          //     pictureUrl: props.pictureUrl,
          //     path: window.location.pathname,
          //     search: window.location.search,
          //   })
          // )
          await bookQueue({
            variables: {
              seat: props.seat,
              name: props.name,
              userId: props.userId,
              branchId: props.branchId,
              pictureUrl: props.pictureUrl,
            },
          })

          if (props.setBookQueueSeat) {
            props.setBookQueueSeat(2)
            props.setBookQueueName('')
          }
        }
      }
      props.setVisible(false)
    } catch (error) {
      window.alert(JSON.stringify(error))
    }
  }

  const onCancel = () => {
    if (props.allowOnCancel) {
      props.onCancel()
    }

    if (!props.permanent) {
      props.setVisible(false)
      if (props.setBookQueueSeat) {
        props.setBookQueueSeat(2)
      }
    }
  }

  if (props.visible) {
    return (
      <>
        <Global
          styles={css`
            .popup-width {
              width: 90vw;
            }

            @media (min-width: 768px) {
              .popup-width {
                width: 90vw;
              }
            }
          `}
        />
        <div
          className={`fixed w-full h-full top-0 right-0 flex justify-center items-center`}
          style={{ backgroundColor: 'rgb(0,0,0,0.3)', zIndex: 999 }}
          onClick={() => {
            onCancel()
          }}
        >
          <div
            className="bg-white text-center relative popup-width"
            style={{ borderRadius: '0.5rem' }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {props.permanent ? (
              ''
            ) : (
              <div className="absolute right-0 top-0 pt-2 pr-2">
                <CloseOutlined
                  onClick={() => {
                    onCancel()
                  }}
                  style={{ fontSize: '3em' }}
                />
              </div>
            )}
            {props.content}
            <div
              className={`flex justify-center text-white text-4xl pt-6 pb-6 ${
                props.disabled ? 'hidden' : ''
              }`}
              onClick={() => {
                insistEvent()
              }}
              style={{
                backgroundColor: `${props.buttonColor}`,
                borderBottomLeftRadius: '0.5rem',
                borderBottomRightRadius: '0.5rem',
              }}
            >
              {props.buttonText}
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return null
  }
}
export default CustomModal
