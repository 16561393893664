import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Input, Button, Checkbox, message, Select } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { GET_BRANCHES, LOGIN } from "../../utils/graphql";
// import { useSocket } from 'use-socketio';
import logo from "../../imgs/kopihub-logo.png";

interface loginInput {
  username: string;
  password: string;
}

const Home = () => {
  // const { subscribe, unsubscribe } = useSocket("hello", (dataFromServer) =>
  //   console.log(dataFromServer)
  // );
  // useEffect(()=>{
  //   subscribe()
  //   return ()=>{
  //     unsubscribe()
  //   }
  // },[])

  const history = useHistory();
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
  };

  const { data: branchesData } = useQuery(GET_BRANCHES);

  const [loggedUser, { error, loading, data }] = useMutation(LOGIN, {
    onCompleted: (res) => {
      console.log("login succes", res);
      sessionStorage.setItem("loggedUserRole", res.login.role);
      sessionStorage.setItem("loggedId", res.login.id);
      sessionStorage.setItem("loggedStatus", "LOGGED_IN");
      sessionStorage.setItem("loggedUserId", res.login.userId);
      const branchId = sessionStorage.getItem("branch");
      if (branchId) {
        history.push("/SelectRole");
      }
    },
    onError: (err) => {
      message.error(err.message);
      const branchId = sessionStorage.getItem("branch");
      sessionStorage.clear();
      if (branchId) sessionStorage.setItem("branch", branchId);
    },
  });
  const onFinish = (value: loginInput) => {
    sessionStorage.setItem("loggedUserName", value.username);
    if (value.username === "phitsanulok") {
      sessionStorage.setItem("branch", "522b8ecb-fd3a-49af-8013-d4c9277692fd")
    }
    loggedUser({
      variables: {
        userName: value.username,
        password: value.password,
      },
    });
  };

  // sessionStorage.setItem("loggedStatus","NOT_LOGGED_IN")
  if (sessionStorage.getItem("loggedUserId")) {
    history.push("/SelectRole");
  }
  const [form] = Form.useForm();

  return (
    <div
      className="flex items-center justify-center"
      style={{ background: "#FFFCF9", width: "100vw", height: "100vh" }}
    >
      <div className="w-3/4 max-w-screen-md text-center">
        <img src={logo} className="m-auto mb-8"></img>
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
            className="fiex justify-center"
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Username"
              size="large"
              className="text-2xl"
              style={{ borderRadius: "5px", fontSize: "22px" }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            className="fiex justify-center"
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              size="large"
              style={{ borderRadius: "5px", fontSize: "22px" }}
            />
          </Form.Item>
          {(form.getFieldValue('username') && (form.getFieldValue('username') !== "phitsanulok")) && (
            <Form.Item
              name="branch"
              rules={[{ required: true, message: "Please select branch!" }]}
              className="fiex justify-center"
            >
              <Select
                placeholder="เลือกสาขา"
                className="text-left"
                onChange={(branchid: string) =>
                  sessionStorage.setItem("branch", branchid)
                }
              >
                {branchesData?.branches.map(
                  (branch: { id: string; name: string }) => (
                    <Select.Option key={branch.id} value={branch.id}>
                      {branch.name}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          )}

          {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <a href="">
            Forgot password
          </a>
        </Form.Item> */}

          <Form.Item className="fiex justify-center">
            <Button
              type="primary"
              htmlType="submit"
              className="flex justify-center"
              style={{
                borderRadius: "5px",
                fontSize: "20px",
                height: "auto",
                padding: "0.5em 3em",
                background: "#683830",
                border: "none",
                width: "100%",
              }}
            >
              LOGIN
            </Button>
            <br />
            {/* <a 
            href="./Register"
            className="block underline mt-3"
            style={{color: '#535050'}}
          >
            REGISTER
          </a> */}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Home
