import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GET_MATERIAL_ITEM_CATEGORIES,
  GET_MATERIAL_ITEMS,
  GET_MATERIAL_SUPPLIERS,
  GET_MATERIAL_UNITS,
  CREATE_MATERIAL_UNIT,
  CREATE_MATERIAL_SUPPLIER,
  CREATE_MATERIAL_ITEM,
  CREATE_MATERIAL_ITEM_CATEGORY,
  UPDATE_MATERIAL_ITEM,
  DELETE_MATERIAL_ITEM,
  GET_MATERIAL_SEMI_PRODUCTS,
  DELETE_MATERIAL_SEMI_PRODUCT,
} from "../../utils/graphql";
import CommonHeader from "./CommonHeader";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  DialogEditEventArgs,
  GridComponent,
  IEditCell,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Inject,
  Search,
  Page,
  SearchSettingsModel,
  Filter,
  FilterSettingsModel,
  Sort,
  DetailRow,
  GridModel,
  Column,
  ColumnModel,
  AddEventArgs,
} from "@syncfusion/ej2-react-grids";
import {
  AutoComplete,
  AutoCompleteComponent,
  DropDownListComponent,
} from "@syncfusion/ej2-react-dropdowns";

const MaterialItemPage = () => {
  const [id, setId] = useState(0);
  const customAttributes = { class: "hilight-col" };

  const {
    refetch: getMaterialItem,
    data: material_items,
    loading: material_items_loading,
  } = useQuery(GET_MATERIAL_ITEMS, {
    fetchPolicy: "no-cache",
    variables: { material_item_type: "ITEM" },
  });
  const {
    refetch: getSemiProduct,
    data: material_semi_products,
    loading: material_semi_products_loading,
  } = useQuery(GET_MATERIAL_SEMI_PRODUCTS, {
    fetchPolicy: "no-cache",
  });
  const {
    refetch: getMaterialUnit,
    data: material_units,
    loading: material_units_loading,
  } = useQuery(GET_MATERIAL_UNITS, { fetchPolicy: "no-cache" });
  const {
    refetch: getMaterialSupplier,
    data: material_suppliers,
    loading: material_suppliers_loading,
  } = useQuery(GET_MATERIAL_SUPPLIERS, {
    fetchPolicy: "no-cache",
  });
  const {
    refetch: getMaterialCategory,
    data: material_item_categories,
    loading: material_item_categories_loading,
  } = useQuery(GET_MATERIAL_ITEM_CATEGORIES, {
    fetchPolicy: "no-cache",
  });

  const [createMaterialUnit, { loading: createMaterialUnitLoading }] =
    useMutation(CREATE_MATERIAL_UNIT);
  const [createMaterialSupplier, { loading: createMaterialSupplierLoading }] =
    useMutation(CREATE_MATERIAL_SUPPLIER);
  const [
    createMaterialItemCategory,
    { loading: createMaterialItemCategoryLoading },
  ] = useMutation(CREATE_MATERIAL_ITEM_CATEGORY);
  const [createMaterialItem, { loading: createMaterialItemLoading }] =
    useMutation(CREATE_MATERIAL_ITEM);
  const [updateMaterialItem, { loading: updateMaterialItemLoading }] =
    useMutation(UPDATE_MATERIAL_ITEM);
  const [deleteMaterialItem, { loading: deleteMaterialItemLoading }] =
    useMutation(DELETE_MATERIAL_ITEM);
  const [
    deleteMaterialSemiProduct,
    { loading: deleteMaterialSemiProductLoading },
  ] = useMutation(DELETE_MATERIAL_SEMI_PRODUCT);

  const refetchData = async () => {
    getMaterialItem();
    getSemiProduct();
    getMaterialUnit();
    getMaterialSupplier();
    getMaterialCategory();
  };

  const searchSettings: SearchSettingsModel = { fields: ["name"] };
  const filterSettings: FilterSettingsModel = { type: "Menu" };

  const materialItemDataSource =
    material_items &&
    material_items.materialItems &&
    material_items.materialItems.map((data: any, index: any) => {
      return data["name"];
    });
  const materialUnitDataSource =
    material_units &&
    material_units.materialUnits &&
    material_units.materialUnits.map((data: any, index: any) => {
      return data["name"];
    });
  const materialSupplierDataSource =
    material_suppliers &&
    material_suppliers.materialSuppliers &&
    material_suppliers.materialSuppliers.map((data: any, index: any) => {
      return data["name"];
    });
  const materialCategoryDataSource =
    material_item_categories &&
    material_item_categories.materialItemCategories &&
    material_item_categories.materialItemCategories.map(
      (data: any, index: any) => {
        return data["name"];
      }
    );

  const yieldValidation: object = { required: true, min: 0, max: 100 };
  const minZeroValidation: object = { required: true, min: 0 };
  const minOneValidation: object = { required: true, min: 1 };
  const requiredValidation: object = { required: true };

  const autocomplete = (datasource: any, allowCustom: boolean = true) => {
    let elem = document.createElement("input");
    return {
      create: (args: any) => {
        console.log("create");
        elem.setAttribute("value", args.value ? args.value : "");
        return elem;
      },
      read: (args: any) => {
        console.log("read");
        return args.value;
      },
      destroy: (args: any) => {
        elem.remove();
      },
      write: (args: any) => {
        console.log("write");
        const dObj = new AutoComplete({
          dataSource: datasource,
          value: args["row"] ? args["row"]["value"] : "",
          autofill: true,
          allowCustom: allowCustom,
        });
        dObj.appendTo(elem);
        return args["row"]["value"];
      },
    };
  };

  const actionComplete = async (args: any) => {
    if (args.requestType === "add") {
      setId(id + 1);
    } else if (args.requestType === "delete") {
      const id = args && args.data && args.data[0] && args.data[0].ID;
      console.log(id);
      const result = await deleteMaterialItem({
        variables: {
          id: id,
        },
      });
    }

    if (
      args.requestType === "save" &&
      (args.action === "add" || args.action === "edit")
    ) {
      const data = args.data;
      const amount_unit = data["amount_unit"]["name"]
        ? data["amount_unit"]["name"].trim()
        : "-";
      const pack_unit = data["pack_unit"]["name"]
        ? data["pack_unit"]["name"].trim()
        : "-";
      const material_supplier = data["material_supplier"]["name"]
        ? data["material_supplier"]["name"].trim()
        : "-";
      const material_item_category = data["material_item_category"]["name"]
        ? data["material_item_category"]["name"].trim()
        : "-";
      let amount_unit_id = 0;
      let pack_unit_id = 0;
      let material_supplier_id = 0;
      let material_item_category_id = 0;
      console.log("in here ja");

      if (
        !(
          material_units &&
          material_units.materialUnits.find((d: any, i: any) => {
            if (d.name === amount_unit) {
              amount_unit_id = d.id;
            }
            return d.name === amount_unit;
          })
        )
      ) {
        const result = await createMaterialUnit({
          variables: {
            name: amount_unit,
          },
        });
        amount_unit_id =
          result &&
          result.data &&
          result.data.createOnematerial_unit &&
          result.data.createOnematerial_unit.id;
      }

      if (
        !(
          material_units &&
          material_units.materialUnits.find((d: any, i: any) => {
            if (d.name === pack_unit) {
              pack_unit_id = d.id;
            }
            return d.name === pack_unit;
          })
        ) &&
        pack_unit != amount_unit
      ) {
        const result = await createMaterialUnit({
          variables: {
            name: pack_unit,
          },
        });
        pack_unit_id =
          result &&
          result.data &&
          result.data.createOnematerial_unit &&
          result.data.createOnematerial_unit.id;
      }

      if (
        !(
          material_suppliers &&
          material_suppliers.materialSuppliers.find((d: any, i: any) => {
            if (d.name === material_supplier) {
              material_supplier_id = d.id;
            }
            return d.name === material_supplier;
          })
        )
      ) {
        const result = await createMaterialSupplier({
          variables: {
            name: material_supplier,
          },
        });
        material_supplier_id =
          result &&
          result.data &&
          result.data.createOnematerial_supplier &&
          result.data.createOnematerial_supplier.id;
      }

      if (
        !(
          material_item_categories &&
          material_item_categories.materialItemCategories.find(
            (d: any, i: any) => {
              if (d.name === material_item_category) {
                material_item_category_id = d.id;
              }
              return d.name === material_item_category;
            }
          )
        )
      ) {
        const result = await createMaterialItemCategory({
          variables: {
            name: material_item_category,
          },
        });
        material_item_category_id =
          result &&
          result.data &&
          result.data.createOnematerial_item_category &&
          result.data.createOnematerial_item_category.id;
      }

      if (args.action === "add") {
        const create_material_item_result = await createMaterialItem({
          variables: {
            name: data["name"],
            price: data["price"],
            amount: data["amount"],
            yield: data["yield"],
            material_item_type: "ITEM",
            pack_unit_id: pack_unit_id,
            amount_unit_id: amount_unit_id,
            material_item_category_id: material_item_category_id,
            material_supplier_id: material_supplier_id,
          },
        });

        console.log(create_material_item_result);
      } else {
        const update_material_item_result = await updateMaterialItem({
          variables: {
            id: data["ID"],
            name: data["name"],
            price: data["price"],
            amount: data["amount"],
            yield: data["yield"],
            material_item_type: "ITEM",
            pack_unit_id: pack_unit_id,
            amount_unit_id: amount_unit_id,
            material_item_category_id: material_item_category_id,
            material_supplier_id: material_supplier_id,
          },
        });

        console.log(update_material_item_result);

        //select all material semi by ingredient id
        //      จากนั้นมาลูป select semi by semi id ทีละอัน
        //           sumprice มารวมกัน
        // let sumPrice = 0
        // selected_mat_item && selected_mat_item.material_semi_product && selected_mat_item.material_semi_product.forEach((element: any) => {
        //     const data = element
        //     sumPrice += Math.round(((data['material_ingredient']['price'] / data['material_ingredient']['amount'] / data['material_ingredient']['yield'] * 100 * data['material_item_amount']) + Number.EPSILON) * 100) / 100
        // });
        // const res = updateSemiPrice({
        //     variables: {
        //         id: selectedItem.ID,
        //         price: sumPrice
        //     }
        // })
      }
    }
    if (
      args.requestType === "delete" ||
      (args.requestType === "save" &&
        (args.action === "add" || args.action === "edit"))
    )
      refetchData();
  };

  const editOptions: EditSettingsModel = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    allowEditOnDblClick: false,
  };
  const commands: CommandModel[] = [
    {
      type: "Edit",
      buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
    },
    {
      type: "Save",
      buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
    },
    {
      type: "Cancel",
      buttonOption: { cssClass: "e-flat", iconCss: "e-cancel-icon e-icons" },
    },
  ];
  const toolbarOptions: ToolbarItems[] = [
    "Add",
    "Update",
    "Cancel",
    "Search",
    "Delete",
  ];

  let i = 1;

  return (
    <div>
      <CommonHeader page="material" value="item" />
      <GridComponent
        dataSource={material_items && material_items.materialItems}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        actionComplete={actionComplete.bind(this)}
        allowFiltering={true}
        allowSorting={true}
        allowPaging={true}
        pageSettings={{ pageSize: 20 }}
        searchSettings={searchSettings}
        filterSettings={filterSettings}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="ID"
            headerText="ID"
            visible={false}
            valueAccessor={(field, data: any, column) => {
              return data["ID"]
                ? data["ID"]
                : material_items &&
                  material_items.materialItems &&
                  material_items.materialItems[0]
                ? material_items.materialItems[0].ID + 1
                : 1;
            }}
            isPrimaryKey={true}
            validationRules={requiredValidation}
          />
          <ColumnDirective
            headerText="No"
            allowEditing={false}
            width="6%"
            textAlign="Center"
            headerTextAlign="Center"
            valueAccessor={(field, data: any, column) => {
              return (
                material_items &&
                material_items.materialItems &&
                material_items.materialItems.findIndex((d: any) => {
                  return d.ID === data.ID;
                }) + 1
              );
            }}
          />
          <ColumnDirective
            field="name"
            headerText="รายการ"
            validationRules={requiredValidation}
            width="15%"
          />
          {materialCategoryDataSource && (
            <ColumnDirective
              field="material_item_category.name"
              headerText="ประเภท"
              edit={autocomplete(materialCategoryDataSource)}
              width="15%"
            />
          )}
          {materialUnitDataSource && (
            <ColumnDirective
              field="pack_unit.name"
              headerText="หน่วยรับ"
              edit={autocomplete(materialUnitDataSource)}
              width="8%"
              allowFiltering={false}
            />
          )}
          <ColumnDirective
            field="price"
            headerText="ราคา"
            editType="numericedit"
            valueAccessor={(field, data: any, column) => {
              return data[field] + " ฿";
            }}
            validationRules={minZeroValidation}
            width="10%"
          />
          <ColumnDirective
            field="amount"
            headerText="จำนวน"
            editType="numericedit"
            validationRules={minOneValidation}
            width="10%"
          />
          {materialUnitDataSource && (
            <ColumnDirective
              field="amount_unit.name"
              headerText="หน่วยย่อย"
              edit={autocomplete(materialUnitDataSource)}
              width="10%"
              allowFiltering={false}
            />
          )}
          <ColumnDirective
            field="yield"
            headerText="ใช้ได้"
            editType="numericedit"
            valueAccessor={(field, data: any, column) => {
              return data[field] + " %";
            }}
            validationRules={yieldValidation}
            width="10%"
          />
          {materialSupplierDataSource && (
            <ColumnDirective
              field="material_supplier.name"
              headerText="Supplier"
              edit={autocomplete(materialSupplierDataSource)}
              width="10%"
            />
          )}
          <ColumnDirective
            headerText="ราคาขาย/ออเดอร์"
            valueAccessor={(field, data: any, column) => {
              return data["price"] && data["amount"] && data["yield"]
                ? Math.round(
                    (data["price"] / data["amount"] / (data["yield"] / 100) +
                      Number.EPSILON) *
                      100
                  ) /
                    100 +
                    " ฿ / " +
                    (data["amount_unit"]["name"]
                      ? data["amount_unit"]["name"]
                      : "หน่วย")
                : "";
            }}
            allowEditing={false}
            customAttributes={customAttributes}
            width="10%"
          />
          <ColumnDirective
            headerText="แก้"
            commands={commands}
            width="10%"
            headerTextAlign="Center"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Edit,
            CommandColumn,
            Toolbar,
            Page,
            Search,
            Filter,
            Sort,
            DetailRow,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default MaterialItemPage;
