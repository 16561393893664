const liff = window.liff;
let isInit = false;
let profile = {};
let liffInfo = {};
let liffId = "1655216608-Gl3yPZWv"
let hardCodeLineProfileData = (()=>{
  try {
    return JSON.parse(process.env.REACT_APP_LINE_PROFILE_JSON)  
  } catch (error) {
  }
})()
let hardCodeLiffInfo = (()=>{
  try {
    return JSON.parse(process.env.REACT_APP_INFO_JSON)  
  } catch (error) {
  }
})()
const search = window.location.search
let customId
try {
  const _customId = (new URLSearchParams(decodeURIComponent(search)))?.get('liff_id')
  if(_customId){
    customId = _customId
  }
  
} catch (error) {
  
}
if(!customId){
  try {
    const _customId = (new URLSearchParams(decodeURIComponent((new URLSearchParams(decodeURIComponent(search)))?.get('liff.state')).split("?")[1])).get('liff_id')
    if(_customId){
      customId = _customId
    }    
  } catch (error) {
    
  }
}

if(customId){
  liffId = customId
}
class liffHelper {
  init() {
    return new Promise((resolve, reject) => {
      
      if (!isInit) {
        if(hardCodeLiffInfo){
          liffInfo = hardCodeLiffInfo
          isInit = true;
          localStorage.setItem('last_liffInfo',JSON.stringify(liffInfo))
          resolve();
          return
        }
        try {
            liff.init({
              liffId:liffId
            }).then((data) => {
              // window.alert('INIT COMPLETED')
              liffInfo = data;
              localStorage.setItem('last_liffInfo',JSON.stringify(liffInfo))
              isInit = true;
              resolve();
            }).catch((err) => {
              
              window.alert(
                `Fail to init LIFF ${liffId} ${search}, please run inside LINE only` + err
              )``;
              console.log("Fail to init LIFF, please run inside LINE only");
              reject();
            })
        
        } catch (error) {
          
          window.alert(error.toString())
          reject();
        }
        
      } else {
        resolve();
      }
    });
  }

  getLIFFInfo() {
    return liffInfo;
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      this.init()
        .then(() => {
          // window.alert('START GET PROFILE')
          if (isInit && !profile.userId) {            
            if(hardCodeLineProfileData){
              profile = hardCodeLineProfileData;
              localStorage.setItem('last_profile',JSON.stringify(profile))
              resolve(profile)
              return 
            }
            liff
              .getProfile()
              .then((pf) => {
                // window.alert('GetProfile'+JSON.stringify(pf))
                profile = pf;
                localStorage.setItem('last_profile',JSON.stringify(profile))
                // profile.email = liff.getDecodedIDToken().email
                resolve(profile);
              })
              .catch((err) => {
                reject(err);                
                window.alert("getProfile"+err);
                console.log("get profile error", err);
              });
          } else {
            resolve(profile);
          }
        })
        .catch((err) => {
          window.alert("getProfileError"+err);
          reject(err);
          
          
        });
    });
  }

  closeWindow() {
    liff.closeWindow();
  }

  openWindow(url, external) {
    liff.openWindow({ url, external });
  }

  sendMessages(messages) {
    const messagesToSend = Array.isArray(messages) ? messages : [messages];
    return new Promise((resolve, reject) => {
      this.init()
        .then(() => {
          liff
            .sendMessages(messagesToSend)
            .then(() => {
              resolve();
            })
            .catch((err) => {
              window.alert("sendMessages"+err);
              reject(err);
            });
        })
        .catch((err) => {
          window.alert("sendMessages"+err);
          reject(err);
        });
    });
  }
}
export default new liffHelper();
