import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import CommonHeader from "./CommonHeader";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import { Modal } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";

import {
  GET_MATERIAL_ITEM_CATEGORIES,
  GET_MATERIAL_ITEMS,
  GET_MATERIAL_SUPPLIERS,
  GET_MATERIAL_UNITS,
  CREATE_MATERIAL_UNIT,
  CREATE_MATERIAL_SUPPLIER,
  CREATE_MATERIAL_ITEM_SEMI,
  CREATE_MATERIAL_ITEM_CATEGORY,
  UPDATE_MATERIAL_ITEM_SEMI,
  DELETE_MATERIAL_ITEM,
  GET_MATERIAL_SEMI_PRODUCTS,
  DELETE_MATERIAL_SEMI_PRODUCT,
  CREATE_MATERIAL_SEMIPRODUCT,
  DELETE_MATERIAL_ITEM_SEMI,
  UPDATE_MATERIAL_SEMIPRODUCT,
  GET_ONE_MATERIAL_ITEM,
  UPDATE_SEMI_PRICE,
} from "../../utils/graphql";

import {
  MaterialItem,
  MaterialItemCategory,
  MaterialItemSupplier,
  MaterialSemiProduct,
  MaterialUnit,
} from "../../types/MaterialItem";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  GridComponent,
  Toolbar,
  Edit,
  Inject,
  Search,
  Page,
  Filter,
  Sort,
  DetailRow,
  GridModel,
  ColumnModel,
} from "@syncfusion/ej2-react-grids";
import { AutoComplete } from "@syncfusion/ej2-react-dropdowns";
import { Autocomplete } from "@material-ui/lab";

const customAttributes = { class: "hilight-col" };
const minOneValidation = { required: true, min: 1 };
const requiredValidation = { required: true };
const autocomplete = (datasource: any, allowCustom: boolean = true) => {
  let elem: any = document.createElement("input");
  return {
    create: (args: any) => {
      console.log("create");
      elem.setAttribute("value", args.value ? args.value : "");
      return elem;
    },
    read: (args: any) => {
      console.log("read");
      return args.value;
    },
    destroy: (args: any) => {
      elem.remove();
    },
    write: (args: any) => {
      console.log("write");
      const dObj = new AutoComplete({
        dataSource: datasource,
        value: args["row"] ? args["row"]["value"] : "",
        autofill: true,
        allowCustom: allowCustom,
      });
      dObj.appendTo(elem);
      return args["row"]["value"];
    },
  };
};

const MaterialSemiProductPage = () => {
  const { refetch: getMaterialItem, data: material_items_items } = useQuery<{
    materialItems: MaterialItem[];
  }>(GET_MATERIAL_ITEMS, {
    fetchPolicy: "no-cache",
    variables: { material_item_type: "ITEM" },
  });
  const { refetch: getSemiProduct, data: material_items } = useQuery<{
    materialItems: MaterialItem[];
  }>(GET_MATERIAL_ITEMS, {
    fetchPolicy: "no-cache",
    variables: { material_item_type: "SEMIPRODUCT" },
  });
  const { refetch: getMaterialSemiProduct, data: material_semi_products } =
    useQuery(GET_MATERIAL_SEMI_PRODUCTS, {
      fetchPolicy: "no-cache",
    });
  const { refetch: getMaterialUnit, data: material_units } = useQuery<{
    materialUnits: MaterialUnit[];
  }>(GET_MATERIAL_UNITS, {
    fetchPolicy: "no-cache",
  });
  const { refetch: getMaterialSupplier, data: material_suppliers } = useQuery<{
    materialSuppliers: MaterialItemSupplier[];
  }>(GET_MATERIAL_SUPPLIERS, {
    fetchPolicy: "no-cache",
  });
  const { refetch: getMaterialCategory, data: material_item_categories } =
    useQuery<{
      materialItemCategories: MaterialItemCategory[];
    }>(GET_MATERIAL_ITEM_CATEGORIES, {
      fetchPolicy: "no-cache",
    });

  const [deleteMaterialItem] = useMutation(DELETE_MATERIAL_ITEM);
  const [deleteMaterialItemSemi] = useMutation(DELETE_MATERIAL_ITEM_SEMI);
  const [createMaterialUnit] = useMutation(CREATE_MATERIAL_UNIT);
  const [createMaterialSupplier] = useMutation(CREATE_MATERIAL_SUPPLIER);
  const [createMaterialItemCategory] = useMutation(
    CREATE_MATERIAL_ITEM_CATEGORY
  );
  const [createMaterialItem] = useMutation(CREATE_MATERIAL_ITEM_SEMI);
  const [updateMaterialItem] = useMutation(UPDATE_MATERIAL_ITEM_SEMI);

  const refetchData = async () => {
    getSemiProduct();
    getMaterialItem();
    getMaterialSemiProduct();
    getMaterialSupplier();
    getMaterialUnit();
    getMaterialCategory();
  };

  const actionComplete = async (args: any) => {
    console.log("actionComplete", args);
    if (args.requestType === "add") {
      // setId(id + 1)
    } else if (args.requestType === "delete") {
      console.log("delete");
      const ID = args?.data && args.data[0] && args?.data[0].ID;
      await deleteMaterialItemSemi({
        variables: {
          id: ID,
        },
      });
      await deleteMaterialItem({
        variables: {
          id: ID,
        },
      });
    }

    if (
      args.requestType === "save" &&
      (args.action === "add" || args.action === "edit")
    ) {
      const data = args.data;
      const amount_unit = data["amount_unit"]["name"].trim();
      const pack_unit = data["pack_unit"]["name"].trim();
      const material_supplier = data["material_supplier"]["name"].trim();
      const material_item_category =
        data["material_item_category"]["name"].trim();
      let amount_unit_id = 0;
      let pack_unit_id = 0;
      let material_supplier_id = 0;
      let material_item_category_id = 0;

      if (
        !(
          material_units &&
          material_units.materialUnits.find((d: any, i: any) => {
            if (d.name === amount_unit) {
              amount_unit_id = d.id;
            }
            return d.name === amount_unit;
          })
        )
      ) {
        const result = await createMaterialUnit({
          variables: {
            name: amount_unit,
          },
        });
        amount_unit_id =
          result &&
          result.data &&
          result.data.createOnematerial_unit &&
          result.data.createOnematerial_unit.id;
      }

      if (
        !(
          material_units &&
          material_units.materialUnits.find((d: any, i: any) => {
            if (d.name === pack_unit) {
              pack_unit_id = d.id;
            }
            return d.name === pack_unit;
          })
        ) &&
        pack_unit !== amount_unit
      ) {
        const result = await createMaterialUnit({
          variables: {
            name: pack_unit,
          },
        });
        pack_unit_id =
          result &&
          result.data &&
          result.data.createOnematerial_unit &&
          result.data.createOnematerial_unit.id;
      }

      if (
        !(
          material_suppliers &&
          material_suppliers.materialSuppliers.find((d: any, i: any) => {
            if (d.name === material_supplier) {
              material_supplier_id = d.id;
            }
            return d.name === material_supplier;
          })
        )
      ) {
        const result = await createMaterialSupplier({
          variables: {
            name: material_supplier,
          },
        });
        material_supplier_id =
          result &&
          result.data &&
          result.data.createOnematerial_supplier &&
          result.data.createOnematerial_supplier.id;
      }

      if (
        !(
          material_item_categories &&
          material_item_categories.materialItemCategories.find(
            (d: any, i: any) => {
              if (d.name === material_item_category) {
                material_item_category_id = d.id;
              }
              return d.name === material_item_category;
            }
          )
        )
      ) {
        const result = await createMaterialItemCategory({
          variables: {
            name: material_item_category,
          },
        });
        material_item_category_id =
          result &&
          result.data &&
          result.data.createOnematerial_item_category &&
          result.data.createOnematerial_item_category.id;
      }

      if (args.action === "add") {
        const create_material_item_result = await createMaterialItem({
          variables: {
            name: data["name"],
            price: data["price"],
            amount: data["amount"],
            yield: 100,
            material_item_type: "ITEM",
            pack_unit_id: pack_unit_id,
            amount_unit_id: amount_unit_id,
            material_item_category_id: material_item_category_id,
            material_supplier_id: material_supplier_id,
          },
        });

        console.log(create_material_item_result);
      } else {
        await updateMaterialItem({
          variables: {
            id: data["id"],
            name: data["name"],
            price: data["price"],
            amount: data["amount"],
            yield: 100,
            material_item_type: "ITEM",
            pack_unit_id: pack_unit_id,
            amount_unit_id: amount_unit_id,
            material_item_category_id: material_item_category_id,
            material_supplier_id: material_supplier_id,
          },
        });
      }
    }
    if (["add", "delete", "save"].includes(args.requestType)) refetchData();
  };

  const formatNumber = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };
  const edit = (a: any) => {
    const id = a.target.closest(".e-row").children[1].innerHTML;
    const selected_mat_item = material_items?.materialItems?.find((d: any) => {
      return d.ID.toString() === id;
    });
    console.log("edit");
    setSelectedItem(selected_mat_item);
    setOpen(true);
  };
  const commands: CommandModel[] = [
    {
      type: "None",
      buttonOption: {
        cssClass: "e-flat",
        iconCss: "e-edit e-icons",
        click: (e) => {
          edit(e);
        },
      },
    },
    {
      type: "Save",
      buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
    },
    {
      type: "Cancel",
      buttonOption: { cssClass: "e-flat", iconCss: "e-cancel-icon e-icons" },
    },
  ];
  const childGridOptions: GridModel = {
    columns: [
      { field: "ID", headerText: "ID", visible: false },
      {
        field: "item_id",
        headerText: "item_id",
        visible: false,
        isPrimaryKey: true,
      },
      {
        field: "material_ingredient.name",
        headerText: "ส่วนผสม",
      },
      {
        field: "material_item_amount",
        headerText: "จำนวน",
        editType: "numericedit",
        valueAccessor: (field: string, data: any, column: ColumnModel) => {
          return (
            data["material_item_amount"] +
            " " +
            (data &&
            data["material_ingredient"] &&
            data["material_ingredient"]["amount_unit"] &&
            data["material_ingredient"]["amount_unit"]["name"]
              ? data["material_ingredient"]["amount_unit"]["name"]
              : "หน่วย")
          );
        },
        validationRules: requiredValidation,
      },
      {
        headerText: "ราคาขาย/ออเดอร์",
        allowEditing: false,
        customAttributes: customAttributes,
        valueAccessor: (field: string, data: any, column: ColumnModel) => {
          return data &&
            data["material_ingredient"] &&
            data["material_ingredient"]["amount_unit"]
            ? formatNumber(
                data["material_ingredient"]["price"] /
                  data["material_ingredient"]["amount"] /
                  (data["material_ingredient"]["yield"] / 100)
              )
            : 0;
        },
      },
      {
        headerText: "ราคา",
        allowEditing: false,
        customAttributes: customAttributes,
        valueAccessor: (field: string, data: any, column: ColumnModel) => {
          return data &&
            data["material_ingredient"] &&
            data["material_ingredient"]["amount_unit"]
            ? Math.round(
                ((data["material_ingredient"]["price"] /
                  data["material_ingredient"]["amount"] /
                  data["material_ingredient"]["yield"]) *
                  100 *
                  data["material_item_amount"] +
                  Number.EPSILON) *
                  100
              ) /
                100 +
                " ฿"
            : "";
        },
      },
    ],
    dataSource: material_semi_products?.materialSemiProducts,
    queryString: "ID",
  };

  const [selectedItem, setSelectedItem] =
    useState<MaterialItem | undefined | null>(undefined);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <CommonHeader page="material" value="semi" />
      <Button
        color="primary"
        onClick={() => {
          setSelectedItem(null);
          setOpen(true);
        }}
      >
        <AddIcon className="pr-1 my-2" /> เพิ่มวัตถุดิบแปรรูป
      </Button>
      <AddEditModal
        open={open}
        setOpen={setOpen}
        refetch={refetchData}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        material_units={material_units?.materialUnits || []}
        material_items_items={material_items_items?.materialItems || []}
      />
      <GridComponent
        dataSource={material_items?.materialItems}
        editSettings={{
          allowDeleting: true,
          allowEditOnDblClick: false,
        }}
        toolbar={["Delete", "Search"]}
        actionComplete={actionComplete}
        allowFiltering={true}
        allowSorting={true}
        allowPaging={true}
        pageSettings={{ pageSize: 20 }}
        searchSettings={{ fields: ["name"] }}
        filterSettings={{ type: "Menu" }}
        childGrid={childGridOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            visible={false}
            field="id"
            headerText="ID"
            valueAccessor={(field, data: any, column) => {
              if (data.ID) return data.ID;

              if (material_items?.materialItems)
                return material_items.materialItems[0]?.ID + 1 || 1;
            }}
            isPrimaryKey={true}
            validationRules={requiredValidation}
          />
          <ColumnDirective
            field="name"
            headerText="รายการ"
            validationRules={requiredValidation}
          />
          <ColumnDirective
            field="amount"
            headerText="ปริมาณที่ได้"
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return data["amount"]
                ? data["amount"] + " " + data["amount_unit"]["name"]
                : "-";
            }}
          />{" "}
          <ColumnDirective
            field="price"
            headerText="ราคาทั้งหมด"
            allowFiltering={false}
            editType="numericedit"
            valueAccessor={(field, data: any, column) => {
              return formatNumber(data.price || 0) + " ฿";
            }}
            customAttributes={customAttributes}
          />
          <ColumnDirective
            commands={commands}
            headerText="แก้ไข"
            headerTextAlign="Center"
            textAlign="Center"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Edit,
            CommandColumn,
            Toolbar,
            Page,
            Search,
            Filter,
            Sort,
            DetailRow,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default MaterialSemiProductPage;

interface AddEditModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  refetch: () => void;
  selectedItem: MaterialItem | undefined | null;
  setSelectedItem: (item: MaterialItem | undefined | null) => void;
  material_items_items: MaterialItem[] | undefined | null;
  material_units: MaterialUnit[] | undefined | null;
}

const AddEditModal = ({
  open,
  setOpen,
  refetch,
  selectedItem,
  setSelectedItem,
  material_units,
  material_items_items,
}: AddEditModalProps) => {
  const {
    refetch: refetchSingleMaterialItem,
    data: material_item,
    loading,
  } = useQuery<{
    materialItem: MaterialItem;
  }>(GET_ONE_MATERIAL_ITEM, {
    fetchPolicy: "no-cache",
    variables: { id: selectedItem?.ID || -1 },
  });

  const [createMaterialUnit] = useMutation(CREATE_MATERIAL_UNIT);
  const [createMaterialItem, { loading: loadingCreateMaterialItem }] =
    useMutation(CREATE_MATERIAL_ITEM_SEMI);
  const [createMaterialSemiProduct] = useMutation(CREATE_MATERIAL_SEMIPRODUCT);
  const [updateMaterialItem, { loading: loadingUpdateMaterialItem }] =
    useMutation(UPDATE_MATERIAL_ITEM_SEMI);
  const [updateMaterialSemiProduct] = useMutation(UPDATE_MATERIAL_SEMIPRODUCT);
  const [deleteMaterialSemiProduct] = useMutation(DELETE_MATERIAL_SEMI_PRODUCT);
  const [updateSemiPrice] = useMutation(UPDATE_SEMI_PRICE);

  const [name, setName]: any = useState(null);
  const [amount, setAmount]: any = useState(null);
  const [amount_per_pack, setAmount_per_pack]: any = useState(null);
  const [amount_unit_name, setAmount_unit_name]: any = useState(null);
  const [pack_unit_name, setPack_unit_name]: any = useState(null);

  const material_item_options = material_items_items?.map((d) => d.name) || [];
  const unit_options = material_units?.map((d) => d.name) || [];

  useEffect(() => {
    if (open) {
      if (!selectedItem) {
        setName(null);
        setAmount(null);
        setAmount_per_pack(null);
        setAmount_unit_name(null);
        setPack_unit_name(null);
      }

      console.log("refetchsingle");
      refetchSingleMaterialItem({ variables: { id: selectedItem?.ID || -1 } });
    } else {
      refetch();
    }
  }, [open, selectedItem]);

  useEffect(() => {
    // console.log("somthing...")
    if (material_item) {
      setName(material_item?.materialItem?.name);
      setAmount(material_item?.materialItem?.amount);
      setAmount_per_pack(material_item?.materialItem?.amount_per_pack);
      setAmount_unit_name(material_item?.materialItem?.amount_unit?.name);
      setPack_unit_name(material_item?.materialItem?.pack_unit?.name);
    }
  }, [material_item]);

  const createItem = async () => {
    let amount_unit_id = 1;
    let pack_unit_id = 1;

    if (
      amount_unit_name &&
      !material_units?.find((d: any, i: any) => {
        if (d.name === amount_unit_name) {
          amount_unit_id = d.id;
        }
        return d.name === amount_unit_name;
      })
    ) {
      const result = await createMaterialUnit({
        variables: {
          name: amount_unit_name,
        },
      });
      amount_unit_id =
        result &&
        result.data &&
        result.data.createOnematerial_unit &&
        result.data.createOnematerial_unit.id;
    }

    if (
      pack_unit_name &&
      !material_units?.find((d: any, i: any) => {
        if (d.name === pack_unit_name) {
          pack_unit_id = d.id;
        }
        return d.name === pack_unit_name;
      }) &&
      pack_unit_name !== amount_unit_name
    ) {
      const result = await createMaterialUnit({
        variables: {
          name: pack_unit_name,
        },
      });
      pack_unit_id =
        result &&
        result.data &&
        result.data.createOnematerial_unit &&
        result.data.createOnematerial_unit.id;
    }

    if (selectedItem) {
      await updateMaterialItem({
        variables: {
          id: selectedItem.ID,
          name: name,
          amount: parseFloat(amount),
          amount_per_pack: parseFloat(amount_per_pack),
          pack_unit_id: pack_unit_id,
          amount_unit_id: amount_unit_id,
          yield: 100,
        },
      });
    } else {
      const create_material_item_result = await createMaterialItem({
        variables: {
          name: name,
          amount: parseFloat(amount),
          amount_per_pack: parseFloat(amount_per_pack),
          material_item_type: "SEMIPRODUCT",
          pack_unit_id: pack_unit_id,
          amount_unit_id: amount_unit_id,
          yield: 100,
        },
      });
      setSelectedItem(create_material_item_result.data.createOnematerial_item);
    }
    console.log("inhere2");
    refetchSingleMaterialItem();
    refetch();
    // setShowSuccessMessage(true);
  };

  const modalActionComplete = async (args: any) => {
    console.log("modalActionComplete", args);

    if (args.requestType === "delete") {
      console.log("delete");
      const ID = args?.data && args.data[0]?.material_ingredient?.id;
      await deleteMaterialSemiProduct({
        variables: {
          material_semi_product_id: selectedItem?.ID,
          material_ingredient_id: ID,
        },
      });
    }

    if (
      args.requestType === "save" &&
      (args.action === "add" || args.action === "edit")
    ) {
      console.log("saving!");
      const data = args.data;
      const material_item_amount = data.amount;
      const name = data.material_ingredient.name;
      let material_ingredient_id = 0;

      (material_items_items || []).find((d: any, i: any) => {
        if (d.name === name) {
          material_ingredient_id = d.ID;
        }
        return d.name === pack_unit_name;
      });

      if (args.action === "add") {
        console.log("adding!");
        console.log({
          material_semi_product_id: selectedItem?.ID,
          material_ingredient_id: material_ingredient_id,
          material_item_amount: material_item_amount,
        });
        await createMaterialSemiProduct({
          variables: {
            material_semi_product_id: selectedItem?.ID,
            material_ingredient_id: material_ingredient_id,
            material_item_amount: material_item_amount,
          },
        });
      } else if (args.action === "edit") {
        console.log("editing");
        const old_ingredient_id = args.rowData.material_ingredient.id;
        // same material = update
        if (old_ingredient_id === material_ingredient_id) {
          console.log("updating");
          await updateMaterialSemiProduct({
            variables: {
              semi_product_id: selectedItem?.ID,
              ingredient_id: material_ingredient_id,
              material_item_amount: material_item_amount,
            },
          });
        }
        // different mat = delete and recreate
        else {
          await deleteMaterialSemiProduct({
            variables: {
              material_semi_product_id: selectedItem?.ID,
              material_ingredient_id: old_ingredient_id,
            },
          });
          //create new one
          await createMaterialSemiProduct({
            variables: {
              material_semi_product_id: selectedItem?.ID,
              material_ingredient_id: material_ingredient_id,
              material_item_amount: material_item_amount,
            },
          });
        }
        const res = await refetchSingleMaterialItem();
        const price = (
          res?.data?.materialItem?.material_semi_product || []
        ).reduce((acc, data) => {
          const {
            material_ingredient: { price, amount, yield: mat_yield },
            material_item_amount,
          } = data;

          const p =
            Math.round(
              ((price / amount / mat_yield) * 100 * material_item_amount +
                Number.EPSILON) *
                100
            ) / 100;
          return acc + p;
        }, 0);
        await updateSemiPrice({
          variables: {
            id: selectedItem?.ID,
            price: price,
          },
        });
      }
    }
    if (
      args.requestType === "delete" ||
      (args.requestType === "save" &&
        (args.action === "add" || args.action === "edit"))
    )
      refetchSingleMaterialItem();

    // console.log("refetch");
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        className="w-3/4 bg-white px-8 py-6 absolute border-gray-300 overflow-y-scroll"
        style={{
          minHeight: "95%",
          maxHeight: "95%",
          borderRadius: "0.5rem",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        {loading ? (
          <div>loading...</div>
        ) : (
          <>
            <div className="flex items-center pb-6">
              {selectedItem
                ? `แก้ไขวัตถุดิบแปรรูป ${name}`
                : "เพิ่มวัตถุดิบแปรรูป"}
            </div>
            <div className="flex w-full flex-wrap">
              <div className="mb-6 w-5/12">
                <TextField
                  className="w-full"
                  required
                  label="ชื่อวัตถุดิบแปรรูป"
                  placeholder="เบสหมู"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-3/12">
                <TextField
                  className="w-full"
                  label="ปริมาณ (ไม่จำเป็น)"
                  placeholder="5000"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  value={amount}
                  onChange={(e: any) => {
                    setAmount(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const volume =
                              material_item?.materialItem?.material_semi_product?.reduce(
                                (sum, d: MaterialSemiProduct) => {
                                  const {
                                    material_item_amount,
                                    material_ingredient: {
                                      amount_unit: { name: unit_name },
                                    },
                                  } = d;

                                  if (
                                    unit_name ===
                                    material_item?.materialItem?.amount_unit
                                      .name
                                  )
                                    return sum + material_item_amount;
                                  return sum;
                                },
                                0
                              );
                            setAmount(volume);
                          }}
                        >
                          cal
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <Autocomplete
                  freeSolo
                  options={unit_options}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="หน่วยย่อย *"
                      placeholder="กรัม"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  value={amount_unit_name}
                  onInputChange={(e: any) => {
                    if (e && e.target && e.target.value) {
                      setAmount_unit_name(e.target.value);
                    }
                  }}
                  onChange={(e: any, data: any) => {
                    setAmount_unit_name(data);
                  }}
                />
              </div>
              <div className="w-1/12"></div>

              <div className="mb-4">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    loadingCreateMaterialItem || loadingUpdateMaterialItem ? (
                      <></>
                    ) : selectedItem ? (
                      <DoneIcon />
                    ) : (
                      <AddIcon />
                    )
                  }
                  onClick={createItem}
                >
                  {` ${
                    loadingCreateMaterialItem || loadingUpdateMaterialItem
                      ? "กำลังบันทึก..."
                      : selectedItem
                      ? "บันทึก"
                      : "สร้าง"
                  } `}
                </Button>
              </div>
              {selectedItem && (
                <GridComponent
                  dataSource={
                    material_item?.materialItem?.material_semi_product || []
                  }
                  editSettings={{
                    allowAdding: true,
                    allowDeleting: true,
                    allowEditing: true,
                    allowEditOnDblClick: false,
                  }}
                  toolbar={[
                    "Add",
                    "Update",
                    "Edit",
                    "Delete",
                    "Cancel",
                    "Search",
                  ]}
                  actionComplete={modalActionComplete.bind(this)}
                  allowSorting={true}
                  allowPaging={true}
                  pageSettings={{ pageSize: 20 }}
                  searchSettings={{ fields: ["name"] }}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      visible={false}
                      field="ID"
                      headerText="ID"
                      valueAccessor={(field, data: any, column) => {
                        return data?.material_ingredient?.id
                          ? data.material_ingredient.id
                          : "";
                      }}
                      isPrimaryKey={true}
                      validationRules={requiredValidation}
                    />
                    <ColumnDirective
                      field="material_ingredient.name"
                      headerText="ส่วนผสม"
                      allowEditing={false}
                      edit={autocomplete(material_item_options, false)}
                    />
                    <ColumnDirective
                      field="amount"
                      headerText="จำนวน"
                      edit={{ params: { decimals: 2 } }}
                      editType="numericedit"
                      validationRules={requiredValidation}
                      valueAccessor={(field, data: any, column) => {
                        return (
                          data["material_item_amount"] +
                          " " +
                          (data &&
                          data["material_ingredient"] &&
                          data["material_ingredient"]["amount_unit"] &&
                          data["material_ingredient"]["amount_unit"]["name"]
                            ? data["material_ingredient"]["amount_unit"]["name"]
                            : "หน่วย")
                        );
                      }}
                    />
                    <ColumnDirective
                      headerText="ราคาขาย/ออเดอร์"
                      allowEditing={false}
                      editType="numericedit"
                      customAttributes={customAttributes}
                      valueAccessor={(field, data: any, column) => {
                        return data &&
                          data["material_ingredient"] &&
                          data["material_ingredient"]["amount_unit"]
                          ? Math.round(
                              (data["material_ingredient"]["price"] /
                                data["material_ingredient"]["amount"] /
                                (data["material_ingredient"]["yield"] / 100) +
                                Number.EPSILON) *
                                100
                            ) /
                              100 +
                              " ฿ / " +
                              (data["material_ingredient"]["amount_unit"][
                                "name"
                              ]
                                ? data["material_ingredient"]["amount_unit"][
                                    "name"
                                  ]
                                : "หน่วย")
                          : "";
                      }}
                    />
                    <ColumnDirective
                      headerText="ราคา"
                      allowEditing={false}
                      customAttributes={customAttributes}
                      editType="numericedit"
                      valueAccessor={(field, data: any, column) => {
                        return data &&
                          data["material_ingredient"] &&
                          data["material_ingredient"]["amount_unit"]
                          ? Math.round(
                              ((data["material_ingredient"]["price"] /
                                data["material_ingredient"]["amount"] /
                                data["material_ingredient"]["yield"]) *
                                100 *
                                data["material_item_amount"] +
                                Number.EPSILON) *
                                100
                            ) /
                              100 +
                              " ฿"
                          : "";
                      }}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Edit,
                      CommandColumn,
                      Toolbar,
                      Page,
                      Search,
                      Filter,
                      Sort,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
