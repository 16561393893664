import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch, BrowserRouter as Router, Redirect,useLocation } from 'react-router-dom'
import Home from '../pages/Home'
import SelectRole from '../pages/SelectRole'
import TaskView from '../pages/TaskView'
import Register from '../pages/Register'
import EditTask from '../pages/EditTask'
import PrivateRoute from '../routing/PrivateRoute'
import StaffQueue from '../pages/StaffQueue'
import CustomerQueue from '../pages/CustomerQueue'
import CustomerCancelQueue from '../pages/CustomerCancelQueue'
import SetTaskNew from '../pages/SetTaskNew'
import InsertTask from '../pages/InsertTask'
import SetTime from '../pages/SetTime'
import SortTask from '../pages/SortTask'
import EmployeePage from '../pages/EmployeePage'
import PaymentPage from '../pages/PaymentPage'
import WorkLogPage from '../pages/WorkLogPage'
import Payroll from '../pages/Payroll'
import NotifyLog from '../pages/NotifyLog'
import Admin from '../pages/Admin'
import InsertAdmin from '../pages/InsertAdmin'
import EditAdmin from '../pages/EditAdmin'
import AdminRoute from '../routing/AdminRoute'
import MaterialItemPage from '../pages/MaterialItemPage'
import MaterialMenuPage from '../pages/MaterialMenuPage'
import MaterialReportPage from '../pages/MaterialReportPage'
import MaterialSemiProductPage from '../pages/MaterialSemiProductPage'


let params = new URLSearchParams(decodeURIComponent(window.location.search));
let liffPath = params.get("liff.state");


export default () => (
  <>
    <Fragment>
      <Switch>
        {/* <Route exact path="/" component={Home} /> */}
        <Route
          exact
          path="/"
          render={(props) => {
            if (liffPath) {
              if (liffPath.toLowerCase() === '/staffqueue') {
                if (sessionStorage.getItem("loggedUserId")) {
                  return <StaffQueue />
                } else {
                  return <Home />
                }
              } else {                
                if(liffPath.indexOf("customerqueue")>=0){
                  return <CustomerQueue {...props} />
                }else  if(liffPath.indexOf("cancelqueue")>=0){
                  return <CustomerCancelQueue {...props} />
                }else{
                  window.alert("Unknown",window.location.pathname+window.location.search)
                  return <Redirect preserveQueryString to={liffPath} />
                }                
              }
            } else {
              return <Home />
            }
          }
          }
        />
        <PrivateRoute exact path="/SelectRole" component={SelectRole} />
        <PrivateRoute exact path="/TaskView" component={TaskView} />
        <PrivateRoute exact path="/Register" component={Register} />
        <PrivateRoute exact path="/EditTask" component={EditTask} />
        <PrivateRoute exact path="/SortTask" component={SortTask} />
        <PrivateRoute exact path="/InsertTask" component={InsertTask} />
        <PrivateRoute exact path="/SetTaskNew/:id/:taskName/:total/:type" component={SetTaskNew} />
        <PrivateRoute exact path="/SetTime/:id/:status/:finishDate" component={SetTime} />
        <AdminRoute exact path="/NotifyLog" component={NotifyLog} />
        <AdminRoute exact path="/Admin" component={Admin} />
        <AdminRoute exact path="/InsertAdmin" component={InsertAdmin} />
        <AdminRoute exact path="/EditAdmin/:id" component={EditAdmin} />
        <Route exact path="/staffqueue" component={StaffQueue} />
        <Route path="/customerqueue" component={CustomerQueue} />
        <Route exact path="/cancelqueue" component={CustomerCancelQueue} />
        <AdminRoute exact path="/payroll/worklog" component={WorkLogPage} />
        <AdminRoute exact path="/payroll/payment" component={PaymentPage} />
        <AdminRoute exact path="/payroll/employee" component={EmployeePage} />
        <AdminRoute exact path="/payroll" component={EmployeePage} />
        <AdminRoute exact path="/material/item" component={MaterialItemPage} />
        <AdminRoute exact path="/material/semiproduct" component={MaterialSemiProductPage} />
        <AdminRoute exact path="/material/menu" component={MaterialMenuPage} />
        {/* <AdminRoute exact path="/material/report" component={MaterialReportPage} /> */}
        <Route path="*" component={NoMatch} />
      </Switch>
    </Fragment>
  </>
)

function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}{location.search}</code>
      </h3>
    </div>
  );
}