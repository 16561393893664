import { gql } from 'apollo-boost'

let protocol = window.location.protocol === 'https:' ? 'https:' : 'http:'
export const BASE_URL = (process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : 'https://kopihub-queue-api.tillitsdone.com'
)
  .replace('http:', protocol)
  .replace('https:', protocol)

// const { makeExecutableSchema, gql } = require('apollo-server');
export const GET_USERS = gql`
  query GetUsers {
    users {
      id
    }
  }
`

export const GET_BRANCHES = gql`
  query GetBranches {
    branches {
      id
      name
    }
  }
`

export const ALL_NOTIFICATION = gql`
  query notifications {
    notifications {
      id
      message
      hour
      minute
      token
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($name: String!, $userName: String!, $password: String!) {
    createOneUser(
      data: {
        name: $name
        userName: $userName
        password: $password
        enableStatus: SHOW
      }
    ) {
      id
      name
    }
  }
`
export const CREATE_USER1 = gql`
  mutation CreateUser($name: String!, $userName: String!, $password: String!) {
    createUser(name: $name, userName: $userName, password: $password) {
      id
      name
    }
  }
`

export const LOGIN = gql`
  mutation Login($userName: String!, $password: String!) {
    login(userName: $userName, password: $password) {
      id
      role
      userId
      createdAt
    }
  }
`
export const UPDATE_USER = gql`
  mutation UpdateUser($enableStatus: EnableStatus!, $id: String!) {
    updateOneUser(
      data: { enableStatus: $enableStatus }
      where: { id: "5c337622-0ff9-4cdc-b1a9-2ce3f9b4eff3" }
    ) {
      id
      name
      enableStatus
    }
  }
`
export const UPDATE_LOG_USER = gql`
  mutation UpdateLogUser($id: String!, $role: UserRole!) {
    updateOneLogUser(data: { role: $role }, where: { id: $id }) {
      id
      role
      userId
    }
  }
`

export const UPDATE_TASK_STATUS = gql`
  mutation UpdateTaskStatus(
    $id: String!
    $status: TaskStatus!
    $countTime: Int!
    $finishTime: DateTime
  ) {
    updateOneTask(
      data: { status: $status, countTime: $countTime, finishTime: $finishTime }
      where: { id: $id }
    ) {
      id
      name
      status
      countTime
    }
  }
`

export const CREATE_TASK = gql`
  mutation CreateTask(
    $name: String!
    $total: Int!
    $finishTime: DateTime!
    $countTime: Int!
    $userId: String!
  ) {
    createTask(
      name: $name
      total: $total
      finishTime: $finishTime
      countTime: $countTime
      userId: $userId
    ) {
      id
      name
      countTime
      finishTime
      status
      priority
    }
  }
`

export const UPDATE_TASK_ONGOING = gql`
  mutation UpdateTaskOngoing(
    $finishTime: DateTime!
    $countTime: Int!
    $taskId: String!
    $userId: String!
  ) {
    updateTaskOngoing(
      finishTime: $finishTime
      countTime: $countTime
      taskId: $taskId
      userId: $userId
    )
  }
`

export const UPDATE_TASK_TIMEUP = gql`
  mutation UpdateTaskTimeup($taskId: String!, $userId: String!) {
    updateTaskTimeup(taskId: $taskId, userId: $userId)
  }
`

export const UPDATE_TASK_COMPLETE = gql`
  mutation UpdateTaskComplete($taskId: String!, $userId: String!) {
    updateTaskComplete(taskId: $taskId, userId: $userId)
  }
`

export const UPDATE_TASK_CANCEL = gql`
  mutation UpdateTaskCancel($taskId: String!) {
    updateTaskCancel(taskId: $taskId) {
      id
      name
      countTime
      finishTime
      status
      priority
    }
  }
`

export const UPDATE_EDIT_TASK = gql`
  mutation UpdateEditTask($taskId: String!, $name: String!, $total: Int!) {
    UpdateEditTask(taskId: $taskId, name: $name, total: $total) {
      id
      name
      total
      countTime
      finishTime
      status
      priority
    }
  }
`

export const UPDATE_TASK_PRIORITY = gql`
  mutation UpdateTaskPriority($taskId: String!, $priority: Int!) {
    updateTaskPriority(taskId: $taskId, priority: $priority) {
      id
      name
      countTime
      finishTime
      status
      priority
    }
  }
`

export const GET_TASKS = gql`
  query GetTaskAll {
    tasks(
      where: { status: { in: [PENDING, ONGOING, TIMEUP] } }
      orderBy: [{ status: desc }, { priority: asc }, { finishTime: asc }]
    ) {
      id
      name
      countTime
      finishTime
      status
      priority
      total
    }
  }
`

export const GET_PENDING_TASKS = gql`
  query GetPendingTask {
    tasks(where: { status: { in: [PENDING] } }, orderBy: [{ priority: asc }]) {
      id
      name
      countTime
      finishTime
      status
      priority
      total
      updatedAt
    }
  }
`

export const GET_STEAMER = gql`
  query GetSteamer {
    steamers(orderBy: [{ steamerNo: asc }]) {
      id
      steamerNo
      machineNo
      updatedAt
      updatedBy
      Task {
        id
      }
      taskId
    }
  }
`

export const UPDATE_STEAMER = gql`
  mutation updateOneSteamer($id: String!, $taskId: String!) {
    updateOneSteamer(
      data: { Task: { connect: { id: $taskId } } }
      where: { id: $id }
    ) {
      id
    }
  }
`

export const UPDATE_STEAMER_COMPLETE = gql`
  mutation updateSteamerComplete($taskId: String!) {
    updateSteamerComplete(taskId: $taskId)
  }
`

export const GET_QUEUES = gql`
  query getQueues {
    getQueues {
      recentQueue {
        id
        queueNo
        status
        ordered
        userId
        seat
        name
        pictureUrl
        table {
          tableName
          ochaTableName
        }
      }
      activeQueues {
        id
        queueNo
        status
        calledAt
        ordered
        userId
        seat
        name
        pictureUrl
      }
    }
  }
`

export const GET_QUEUES_WITH_BRANCH = gql`
  query getQueues($branchId: String) {
    getQueues(branchId: $branchId) {
      recentQueue {
        id
        queueNo
        status
        ordered
        branchId
        userId
        seat
        name
        pictureUrl
        table {
          tableName
          ochaTableName
        }
        remark
      }
      activeQueues {
        id
        queueNo
        status
        calledAt
        ordered
        userId
        seat
        name
        createdAt
        pictureUrl
        remark
      }
    }
  }
`

export const GET_SUCCESS_QUEUES = gql`
  query getSuccessQueues($startDate: DateTime, $endDate: DateTime) {
    queues(
      where: {
        AND: [
          { createdAt: { gte: $startDate } }
          { createdAt: { lt: $endDate } }
        ]
        status: { equals: SUCCESS }
      }
      orderBy: { updateAt: desc }
    ) {
      queueNo
      table {
        ochaTableName
        tableName
        id
      }
      updateAt
      orderedAt
      id
    }
  }
`

export const GET_SUCCESS_QUEUES_WITH_BRANCH = gql`
  query getSuccessQueues(
    $branchId: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    queues(
      where: {
        AND: [
          { branchId: { equals: $branchId } }
          { createdAt: { gte: $startDate } }
          { createdAt: { lt: $endDate } }
        ]
        status: { equals: SUCCESS }
      }
      orderBy: { updateAt: desc }
    ) {
      queueNo
      table {
        ochaTableName
        tableName
        id
      }
      updateAt
      orderedAt
      branchId
      id
    }
  }
`

export const GET_CANCELLED_QUEUES = gql`
  query getCancelQueues($branchId: String,$startDate: DateTime, $endDate: DateTime) {
    queues(
      where: {
        AND: [
          { branchId: { equals: $branchId } }
          { createdAt: { gte: $startDate } }
          { createdAt: { lt: $endDate } }
        ]
        status: { equals: CANCELLED }
      }
      orderBy: { updateAt: desc }
    ) {
      queueNo
      updateAt
      cancelled_by_employee
    }
  }
`

export const GET_TABLES = gql`
  query getTables {
    tables {
      id
      ochaTableName
      tableName
    }
  }
`

export const GET_TABLES_WITH_BRANCH = gql`
  query getTables($branchId: String) {
    tables(where: { branchId: { equals: $branchId } }, orderBy: [{ id: asc }]) {
      id
      branchId
      ochaTableName
      tableName
    }
  }
`

export const GET_QUEUE = gql`
  query getQueue($id: Int!) {
    queues(where: { id: { equals: $id } }) {
      id
      queueNo
      ordered
      status
      userId
    }
  }
`

export const GET_MY_QUEUE = gql`
  query getMyQueue($userId: String!,$branchId: String) {
    getMyQueue(userId: $userId,branchId: $branchId) {
      recentQueue {
        id
        queueNo
        status
        ordered
        userId
        seat
        name
        pictureUrl
        table {
          tableName
          ochaTableName
        }
      }
      activeQueues {
        id
        queueNo
        status
        ordered
        userId
        seat
        name
        pictureUrl
      }
    }
  }
`

export const CANCEL_QUEUE = gql`
  mutation cancelQueue($id: Int!, $cancelled_by_employee: Boolean!) {
    cancelQueue(id: $id, cancelled_by_employee: $cancelled_by_employee)
  }
`

export const FETCH_QUEUE = gql`
  mutation fetchQueue($id: Int!, $tableId: Int) {
    fetchQueue(id: $id, tableId: $tableId)
  }
`

export const ORDER_FOOD = gql`
  mutation orderFood($id: Int!) {
    orderFood(id: $id)
  }
`

export const CALL_ORDER = gql`
  mutation callOrder($id: Int!) {
    callOrder(id: $id)
  }
`

export const BOOK_QUEUE = gql`
  mutation bookQueue(
    $userId: String
    $seat: Int!
    $name: String
    $pictureUrl: String
  ) {
    bookQueue(
      userId: $userId
      seat: $seat
      name: $name
      pictureUrl: $pictureUrl
    )
  }
`

export const BOOK_QUEUE_WITH_BRANCH = gql`
  mutation bookQueue(
    $userId: String
    $seat: Int!
    $branchId: String!
    $name: String
    $pictureUrl: String
  ) {
    bookQueue(
      userId: $userId
      seat: $seat
      branchId: $branchId
      name: $name
      pictureUrl: $pictureUrl
    )
  }
`

export const UPDATE_QUEUE_REMARK = gql`
  mutation updateOneQueue($id: Int!, $remark: String!) {
    updateOneQueue(
      where: { id: $id }
      data: { remark: { set: $remark } }
    ) {
      id
    }
  }
`

export const EDIT_TABLE_BY_QUEUE = gql`
  mutation editTableByQueue($queueId: Int!, $tableId: Int!) {
    editTableByQueue(queueId: $queueId, tableId: $tableId)
  }
`

export const GET_EMPLOYEE = gql`
  query getEmployee($id: String!) {
    employees(
      where: { id: { equals: $id }, status: { equals: ACTIVE } }
      orderBy: [{ createdAt: asc }]
    ) {
      id
      name
      fullName
      tel
      lineId
      bank
      bankAccount
      hiringType
      earning
      university {
        id
        name
      }
      faculty {
        id
        name
      }
      profilePictureUrl
      idCardPictureUrl
      employeeWatcher {
        id
        name
        tel
      }
      address
      withdrawableMoney
      withdrawnMoney
      withdrawableHours
      withdrawnHours
      hiringDate
    }
  }
`
export const GET_EMPLOYEES_SIMPLE = gql`
  query getEmployees($statusSearch: EmployeeStatus) {
    employees(
      where: { status: { equals: $statusSearch } }
      orderBy: [{ emp_code: asc }]
    ) {
      id
      name
      hiringType
      earning
      status
      fullName
      tel
      lineId
      bank
      bankAccount
      idCardPictureUrl
      profilePictureUrl
      createdAt
      withdrawableMoney
      withdrawnMoney
      withdrawableHours
      withdrawnHours
      hiringDate
      emp_code
      sync_with_scanner
    }
  }
`

export const GET_EMPLOYEES = gql`
  query getEmployees(
    $textSearch: String
    $statusSearch: EmployeeStatus
    $hiringTypeSearch: HiringType
    $fromCreatedDate: DateTime
    $toCreatedDate: DateTime
  ) {
    employees(
      where: {
        AND: [
          { createdAt: { gte: $fromCreatedDate } }
          { createdAt: { lte: $toCreatedDate } }
        ]
        status: { equals: $statusSearch }
        hiringType: { equals: $hiringTypeSearch }
        OR: [
          { name: { contains: $textSearch } }
          { fullName: { contains: $textSearch } }
          { tel: { contains: $textSearch } }
          { lineId: { contains: $textSearch } }
        ]
      }
      orderBy: [{ emp_code: asc }]
    ) {
      id
      name
      hiringType
      earning
      status
      fullName
      tel
      lineId
      bank
      bankAccount
      idCardPictureUrl
      profilePictureUrl
      createdAt
      withdrawableMoney
      withdrawnMoney
      withdrawableHours
      withdrawnHours
      hiringDate
      emp_code
      sync_with_scanner
    }
  }
`

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $name: String!
    $hiringType: HiringType!
    $earning: Float!
    $university: String
    $faculty: String
  ) {
    createOneEmployee(
      data: {
        name: $name
        hiringType: $hiringType
        earning: $earning
        status: ACTIVE
        university: $university
        faculty: $faculty
      }
    ) {
      id
    }
  }
`

export const UPSERT_EMPLOYEE = gql`
  mutation upsertEmployee(
    $id: String
    $name: String!
    $fullName: String
    $tel: String
    $lineId: String
    $address: String
    $universityName: String
    $facultyName: String
    $hiringType: String!
    $earning: Float!
    $bank: String
    $bankAccount: String
    $employeeWatcherName: String
    $employeeWatcherTel: String
    $profilePictureUrl: String
    $idCardPictureUrl: String
    $hiringDate: DateTime!
  ) {
    upsertEmployee(
      id: $id
      name: $name
      fullName: $fullName
      tel: $tel
      lineId: $lineId
      address: $address
      universityName: $universityName
      facultyName: $facultyName
      hiringType: $hiringType
      earning: $earning
      bank: $bank
      bankAccount: $bankAccount
      employeeWatcherName: $employeeWatcherName
      employeeWatcherTel: $employeeWatcherTel
      profilePictureUrl: $profilePictureUrl
      idCardPictureUrl: $idCardPictureUrl
      hiringDate: $hiringDate
    )
  }
`

export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($id: String!) {
    updateOneEmployee(data: { status: { set: DELETED } }, where: { id: $id }) {
      id
    }
  }
`

export const GET_WORKLOGS = gql`
  query getWorkLogs($empId: String, $startDate: DateTime, $endDate: DateTime) {
    workingHistories(
      where: {
        employeeId: { equals: $empId }
        AND: [
          { createdAt: { gte: $startDate } }
          { createdAt: { lte: $endDate } }
        ]
      }
    ) {
      id
      historyDate
      employee {
        name
        emp_code
        sync_with_scanner
      }
      hours
      earning
    }
  }
`

export const GET_WORKLOG = gql`
  query getWorkLog(
    $textSearch: String
    $fromDate: DateTime
    $toDate: DateTime
  ) {
    workingHistories(
      where: {
        employee: {
          OR: [
            { name: { contains: $textSearch } }
            { fullName: { contains: $textSearch } }
            { tel: { contains: $textSearch } }
            { lineId: { contains: $textSearch } }
          ]
        }
        AND: [
          { historyDate: { gte: $fromDate } }
          { historyDate: { lte: $toDate } }
        ]
      }
      orderBy: [{ historyDate: desc }, { createdAt: desc }]
    ) {
      id
      historyDate
      employee {
        id
        name
        tel
        profilePictureUrl
        emp_code
        sync_with_scanner
      }
      hours
      earning
      earningRate
      createdAt
      sourceType
      status
    }
  }
`

export const GET_A_WORKLOG = gql`
  query getAWorkLog($id: String!) {
    workingHistories(where: { id: { equals: $id } }) {
      id
      createdAt
      updateAt
      employee {
        name
      }
      historyDate
      hours
      hiringType
      earningRate
      earning
      sourceType
      paid
      status
    }
  }
`

export const UPDATE_WORKLOG = gql`
  mutation updateWorkLog($id: String!, $hours: Float!, $earning: Float!) {
    updateWorkLog(id: $id, hours: $hours, earning: $earning)
  }
`

export const CREATE_WORKLOGS = gql`
  mutation createWorkLogs($workLogs: [WorkingHistoryCreateInput!]!) {
    createWorkLogs(workLogs: $workLogs)
  }
`

export const DELETE_WORKLOG = gql`
  mutation deleteWorkLog($id: String!) {
    deleteWorkLog(id: $id)
  }
`

export const GET_EMLOYEES_EARNING = gql`
  query getEmployeesEarning {
    getEmployeesEarning {
      data {
        id
        name
        remainingEarning
      }
    }
  }
`

export const CREATE_PAYROLL = gql`
  mutation createPayroll(
    $employeeId: String!
    $payrollDate: DateTime!
    $paid: Float!
  ) {
    createPayroll(
      employeeId: $employeeId
      payrollDate: $payrollDate
      paid: $paid
    )
  }
`

export const GET_ALL_NOTIFY_LOG = gql`
  query {
    getAllNotifyLog {
      data {
        id
        createdAt
        message
      }
    }
  }
`
export const CHECK_ADMIN = gql`
  query checkAdmin($id: String!) {
    checkAdmin(id: $id) {
      id
      userName
      is_admin
    }
  }
`

export const GET_EMPLOYEE_HISTORIES = gql`
  query getEmployeeHistories(
    $employeeId: String!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getEmployeeHistories(
      employeeId: $employeeId
      startDate: $startDate
      endDate: $endDate
    ) {
      payrolls {
        payrollDate
        paid
      }
      workingHistories {
        historyDate
        hours
        earning
      }
    }
  }
`

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification(
    $message: String!
    $hour: Int!
    $minute: Int!
    $token: String!
    $userId: String!
    $mon: Boolean!
    $tue: Boolean!
    $wed: Boolean!
    $thu: Boolean!
    $fri: Boolean!
    $sat: Boolean!
    $sun: Boolean!
  ) {
    createNotification(
      message: $message
      hour: $hour
      minute: $minute
      token: $token
      userId: $userId
      mon: $mon
      tue: $tue
      wed: $wed
      thu: $thu
      fri: $fri
      sat: $sat
      sun: $sun
    ) {
      id
      message
      hour
      minute
      token
    }
  }
`

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $id: String!
    $message: String!
    $hour: Int!
    $minute: Int!
    $token: String!
    $mon: Boolean!
    $tue: Boolean!
    $wed: Boolean!
    $thu: Boolean!
    $fri: Boolean!
    $sat: Boolean!
    $sun: Boolean!
  ) {
    updateNotification(
      id: $id
      message: $message
      hour: $hour
      minute: $minute
      token: $token
      mon: $mon
      tue: $tue
      wed: $wed
      thu: $thu
      fri: $fri
      sat: $sat
      sun: $sun
    ) {
      id
      message
      hour
      minute
      token
    }
  }
`
export const GET_NOTIFICATION_BY_ID = gql`
  query getNotificationById($id: String!) {
    getNotificationById(id: $id) {
      message
      hour
      minute
      token
      mon
      tue
      wed
      thu
      fri
      sat
      sun
    }
  }
`

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: String!) {
    deleteOneNotification(where: { id: $id }) {
      id
    }
  }
`

export const GET_UNIVERSITIES = gql`
  query getUniversities {
    universities {
      id
      name
    }
  }
`

export const GET_FACULTIES = gql`
  query getFaculties {
    faculties {
      id
      name
    }
  }
`

export const GET_EMPLOYEE_WATCHERS = gql`
  query getEmployeeWatchers {
    employeeWatchers {
      id
      name
      tel
    }
  }
`

export const GET_PAYMENT_HISTORY = gql`
  query getPaymentHistory($employeeId: String!) {
    payrolls(
      where: { employeeId: { equals: $employeeId } }
      orderBy: [{ payrollDate: asc }]
    ) {
      payrollDate
      paid
      WorkingHistory_Payroll {
        allMoney
        paid
        WorkingHistory {
          historyDate
          hours
        }
      }
    }
  }
`

export const GET_MATERIAL_ITEMS = gql`
  query getMaterialItems($material_item_type: material_item_type!) {
    materialItems(
      where: { material_item_type: { equals: $material_item_type } }
      orderBy: { id: desc }
    ) {
      ID: id
      amount_per_pack
      name
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      price
      amount
      piece_per_menu
      yield
      material_item_category {
        name
      }
      material_supplier {
        name
      }
      material_item_type
      material_semi_product {
        material_ingredient {
          id
          name
          price
          amount
          yield
          amount_unit {
            name
          }
        }
        material_item_amount
      }
      note
      cost
    }
  }
`
export const GET_MATERIAL_MENU_ITEMS = gql`
  query getMaterialItems($menu_category: material_menu_categoryWhereInput) {
    materialItems(
      where: {
        material_item_type: { equals: MENU }
        material_menu_category: $menu_category
      }
      orderBy: { id: desc }
    ) {
      ID: id
      amount_per_pack
      name
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      price
      amount
      piece_per_menu
      yield
      material_item_category {
        name
      }
      material_supplier {
        name
      }
      material_item_type
      material_menu_category {
        name
      }
      material_semi_product {
        material_ingredient {
          id
          name
          price
          amount
          yield
          amount_unit {
            name
          }
        }
        material_item_amount
      }
      note
      cost
    }
  }
`

export const GET_MATERIAL_ITEMS_FOR_MENU = gql`
  query getMaterialItems {
    materialItems(
      where: { NOT: { material_item_type: { equals: MENU } } }
      orderBy: { id: desc }
    ) {
      ID: id
      amount_per_pack
      name
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      price
      amount
      yield
      material_item_category {
        name
      }
      material_supplier {
        name
      }
      material_item_type
      material_semi_product {
        material_ingredient {
          id
          name
          price
          amount
          yield
          amount_unit {
            name
          }
          material_semi_product {
            material_ingredient {
              price
              yield
            }
          }
        }
        material_item_amount
      }
      note
    }
  }
`

export const GET_MATERIAL_UNITS = gql`
  query getMaterialUnits {
    materialUnits {
      id
      name
    }
  }
`

export const GET_MATERIAL_SUPPLIERS = gql`
  query getMaterialSuppliers {
    materialSuppliers {
      id
      name
    }
  }
`

export const GET_MATERIAL_ITEM_CATEGORIES = gql`
  query getMaterialItemCategories {
    materialItemCategories {
      id
      name
    }
  }
`

export const GET_MATERIAL_SEMI_PRODUCTS = gql`
  query getMaterialSemiProducts {
    materialSemiProducts {
      ID: material_semi_product_id
      material_ingredient_id
      material_ingredient {
        id
        name
        amount_unit {
          name
        }
        price
        amount
        yield
      }
      material_item_amount
    }
  }
`

export const CREATE_MATERIAL_UNIT = gql`
  mutation createOnematerial_unit($name: String!) {
    createOnematerial_unit(data: { name: $name }) {
      id
    }
  }
`

export const CREATE_MATERIAL_SUPPLIER = gql`
  mutation createOnematerial_supplier($name: String!) {
    createOnematerial_supplier(data: { name: $name }) {
      id
    }
  }
`

export const CREATE_MATERIAL_ITEM_CATEGORY = gql`
  mutation createOnematerial_item_category($name: String!) {
    createOnematerial_item_category(data: { name: $name }) {
      id
    }
  }
`

export const DELETE_MATERIAL_ITEM = gql`
  mutation deleteOnematerial_item($id: Int!) {
    deleteOnematerial_item(where: { id: $id }) {
      id
    }
  }
`

export const DELETE_MATERIAL_SEMI_PRODUCT = gql`
  mutation deleteOnematerial_semi_product(
    $material_semi_product_id: Int!
    $material_ingredient_id: Int!
  ) {
    deleteOnematerial_semi_product(
      where: {
        material_semi_product_id_material_ingredient_id: {
          material_ingredient_id: $material_ingredient_id
          material_semi_product_id: $material_semi_product_id
        }
      }
    ) {
      material_semi_product_id
      material_ingredient_id
    }
  }
`

export const DELETE_MATERIAL_ITEM_SEMI = gql`
  mutation deleteManymaterial_semi_product($id: Int!) {
    deleteManymaterial_semi_product(
      where: { material_semi_product_id: { equals: $id } }
    ) {
      count
    }
  }
`

export const CREATE_MATERIAL_ITEM = gql`
  mutation createMaterialItem(
    $name: String!
    $price: Float!
    $amount: Float!
    $yield: Float!
    $material_item_type: material_item_type!
    $pack_unit_id: Int!
    $amount_unit_id: Int!
    $material_item_category_id: Int!
    $material_supplier_id: Int!
  ) {
    createOnematerial_item(
      data: {
        name: $name
        price: $price
        amount: $amount
        yield: $yield
        material_item_type: $material_item_type
        pack_unit: { connect: { id: $pack_unit_id } }
        amount_unit: { connect: { id: $amount_unit_id } }
        material_item_category: { connect: { id: $material_item_category_id } }
        material_supplier: { connect: { id: $material_supplier_id } }
      }
    ) {
      id
    }
  }
`

export const CREATE_MATERIAL_ITEM_SEMI = gql`
  mutation createMaterialItemSemi(
    $name: String!
    $amount: Float
    $material_item_type: material_item_type!
    $pack_unit_id: Int!
    $amount_unit_id: Int!
    $amount_per_pack: Float
    $piece_per_menu: Int
    $note: String
    $price: Float
    $yield: Float
    $cost: Float
  ) {
    createOnematerial_item(
      data: {
        name: $name
        amount: $amount
        material_item_type: $material_item_type
        pack_unit: { connect: { id: $pack_unit_id } }
        amount_unit: { connect: { id: $amount_unit_id } }
        amount_per_pack: $amount_per_pack
        piece_per_menu: $piece_per_menu
        note: $note
        price: $price
        yield: $yield
        cost: $cost
      }
    ) {
      ID: id
      amount_per_pack
      name
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      amount
      piece_per_menu
      material_semi_product {
        material_ingredient {
          name
          price
          amount
          yield
          amount_unit {
            name
          }
        }
        material_item_amount
      }
      note
      cost
      price
    }
  }
`

export const UPDATE_MATERIAL_ITEM_SEMI = gql`
  mutation updateMaterialItemSemi(
    $id: Int!
    $name: String!
    $amount: Float
    $pack_unit_id: Int!
    $amount_unit_id: Int!
    $amount_per_pack: Float
    $yield: Float
  ) {
    updateOnematerial_item(
      where: { id: $id }
      data: {
        name: { set: $name }
        amount: { set: $amount }
        pack_unit: { connect: { id: $pack_unit_id } }
        amount_unit: { connect: { id: $amount_unit_id } }
        amount_per_pack: { set: $amount_per_pack }
        yield: { set: $yield }
      }
    ) {
      ID: id
      amount_per_pack
      name
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      amount
      material_semi_product {
        material_ingredient {
          name
          price
          amount
          yield
          amount_unit {
            name
          }
        }
        material_item_amount
      }
    }
  }
`

export const UPDATE_MATERIAL_ITEM_MENU = gql`
  mutation updateMaterialItemMenu(
    $id: Int!
    $name: String!
    $amount: Float
    $pack_unit_id: Int!
    $amount_unit_id: Int!
    $amount_per_pack: Float
    $piece_per_menu: Int
    $menu_category: String
    $note: String
    $price: Float
    $cost: Float
  ) {
    updateOnematerial_item(
      where: { id: $id }
      data: {
        name: { set: $name }
        amount: { set: $amount }
        pack_unit: { connect: { id: $pack_unit_id } }
        amount_unit: { connect: { id: $amount_unit_id } }
        material_menu_category: { connect: { name: $menu_category } }
        amount_per_pack: { set: $amount_per_pack }
        piece_per_menu: { set: $piece_per_menu }
        note: { set: $note }
        price: { set: $price }
        cost: { set: $cost }
      }
    ) {
      ID: id
      amount_per_pack
      name
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      amount
      piece_per_menu
      material_semi_product {
        material_ingredient {
          name
          price
          amount
          yield
          amount_unit {
            name
          }
        }
        material_item_amount
      }
      note
      cost
      price
    }
  }
`

export const UPDATE_SEMI_PRICE = gql`
  mutation updateSemiPrice($id: Int!, $price: Float!) {
    updateOnematerial_item(
      where: { id: $id }
      data: { price: { set: $price } }
    ) {
      id
    }
  }
`

export const UPDATE_MATERIAL_ITEM = gql`
  mutation updateMaterialItem(
    $id: Int!
    $name: String!
    $price: Float!
    $amount: Float!
    $yield: Float!
    $material_item_type: material_item_type!
    $pack_unit_id: Int!
    $amount_unit_id: Int!
    $material_item_category_id: Int!
    $material_supplier_id: Int!
  ) {
    updateOnematerial_item(
      where: { id: $id }
      data: {
        name: { set: $name }
        price: { set: $price }
        amount: { set: $amount }
        yield: { set: $yield }
        material_item_type: { set: $material_item_type }
        pack_unit: { connect: { id: $pack_unit_id } }
        amount_unit: { connect: { id: $amount_unit_id } }
        material_item_category: { connect: { id: $material_item_category_id } }
        material_supplier: { connect: { id: $material_supplier_id } }
      }
    ) {
      id
    }
  }
`

export const UPDATE_MATERIAL_SEMIPRODUCT = gql`
  mutation updateOnematerial_semi_product(
    $semi_product_id: Int!
    $ingredient_id: Int!
    $material_item_amount: Float!
  ) {
    updateOnematerial_semi_product(
      where: {
        material_semi_product_id_material_ingredient_id: {
          material_semi_product_id: $semi_product_id
          material_ingredient_id: $ingredient_id
        }
      }
      data: { material_item_amount: { set: $material_item_amount } }
    ) {
      material_semi_product_id
      material_ingredient_id
    }
  }
`

export const CREATE_MATERIAL_SEMIPRODUCT = gql`
  mutation createOnematerial_semi_product(
    $material_semi_product_id: Int!
    $material_ingredient_id: Int!
    $material_item_amount: Float!
  ) {
    createOnematerial_semi_product(
      data: {
        material_semi_product: { connect: { id: $material_semi_product_id } }
        material_ingredient: { connect: { id: $material_ingredient_id } }
        material_item_amount: $material_item_amount
      }
    ) {
      material_semi_product_id
      material_ingredient_id
    }
  }
`
export const GET_ONE_MATERIAL_ITEM = gql`
  query materialItem($id: Int!) {
    materialItem(where: { id: $id }) {
      ID: id
      amount_per_pack
      name
      piece_per_menu
      amount_unit {
        name
      }
      pack_unit {
        name
      }
      price
      amount
      yield
      material_item_category {
        name
      }
      material_supplier {
        name
      }
      material_item_type
      material_semi_product {
        material_ingredient {
          id
          name
          price
          amount
          yield
          amount_unit {
            name
          }
        }
        material_item_amount
      }
      note
      cost
    }
  }
`



export const GET_QR_TOKEN_BY_BRANCH_ID = gql`
  query qrTokensByBranchId($branchId:String!) {
    qrTokens(where:{
      branchId:{
        equals:$branchId
      }
    },orderBy:{
      createdAt:desc
    },first:10) {
      branchId
      id
    }
  }
`

export const CREATE_QR_TOKEN = gql`
  mutation createOneqrToken($branchId:String!) {
    createOneqrToken(data:{
      branchId:$branchId
    }){
      id
      branchId
    }
  }
`



export const GET_BRANCH = gql`
query GET_BRANCH($branchId:String!) {
  branches(where:{
    id:{
      equals:$branchId
    }
  }) {
    id
    name
    liff
  }
}
`