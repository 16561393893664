import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  GET_MATERIAL_ITEM_CATEGORIES,
  GET_MATERIAL_MENU_ITEMS,
  GET_MATERIAL_SUPPLIERS,
  GET_MATERIAL_UNITS,
  CREATE_MATERIAL_UNIT,
  CREATE_MATERIAL_SUPPLIER,
  CREATE_MATERIAL_ITEM_SEMI,
  CREATE_MATERIAL_ITEM_CATEGORY,
  UPDATE_MATERIAL_ITEM_MENU,
  DELETE_MATERIAL_ITEM,
  GET_MATERIAL_SEMI_PRODUCTS,
  DELETE_MATERIAL_SEMI_PRODUCT,
  CREATE_MATERIAL_SEMIPRODUCT,
  GET_MATERIAL_ITEMS_FOR_MENU,
  DELETE_MATERIAL_ITEM_SEMI,
  GET_ONE_MATERIAL_ITEM,
  UPDATE_MATERIAL_SEMIPRODUCT,
} from "../../utils/graphql";
import CommonHeader from "./CommonHeader";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  CommandModel,
  GridComponent,
  Toolbar,
  ToolbarItems,
  Edit,
  EditSettingsModel,
  Inject,
  Search,
  Page,
  SearchSettingsModel,
  Filter,
  FilterSettingsModel,
  Sort,
  DetailRow,
  GridModel,
  ColumnModel,
} from "@syncfusion/ej2-react-grids";
import { AutoComplete } from "@syncfusion/ej2-react-dropdowns";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@material-ui/core";
import { Modal } from "@mui/material";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import { Autocomplete } from "@material-ui/lab";
import { MaterialItem, MaterialUnit } from "src/types/MaterialItem";

const customAttributes = { class: "hilight-col" };
const minOneValidation: object = { required: true, min: 1 };
const requiredValidation: object = { required: true };
const searchSettings: SearchSettingsModel = { fields: ["name"] };
const filterSettings: FilterSettingsModel = { type: "Menu" };

const MenuCategories = [
  "ทั้งหมด",
  "ติ่มซำพรีเมียม",
  "ติ่มซำหาดใหญ่",
  "อาหารจานเดียว",
  "ของหวาน",
  "เครื่องดื่ม",
  "อาหารอื่นๆ",
];

const autocomplete = (datasource: any, allowCustom: boolean = true) => {
  let elem: any = document.createElement("input");
  return {
    create: (args: any) => {
      console.log("create");
      elem.setAttribute("value", args.value ? args.value : "");
      return elem;
    },
    read: (args: any) => {
      console.log("read");
      return args.value;
    },
    destroy: (args: any) => {
      elem.remove();
    },
    write: (args: any) => {
      console.log("write");
      const dObj = new AutoComplete({
        dataSource: datasource,
        value: args["row"] ? args["row"]["value"] : "",
        autofill: true,
        allowCustom: allowCustom,
      });
      dObj.appendTo(elem);
      return args["row"]["value"];
    },
  };
};

const formatNumber = (num: number) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const MaterialMenuPage = () => {
  const [openModal, setOpenModal]: any = useState(false);
  const [selectedItem, setSelectedItem]: any = useState(null);
  const [selectedCategory, setSelectedCategory]: any = useState("ทั้งหมด");

  const { refetch: getMaterialItemForMenu, data: material_items_items } =
    useQuery(GET_MATERIAL_ITEMS_FOR_MENU, {
      fetchPolicy: "no-cache",
    });
  const [
    getMaterialItem,
    {
      data: material_items,
      variables: varMaterrialItem,
      loading: loadingMaterialItem,
    },
  ] = useLazyQuery(GET_MATERIAL_MENU_ITEMS, {
    fetchPolicy: "no-cache",
  });
  const { refetch: getSemiProduct, data: material_semi_products } = useQuery(
    GET_MATERIAL_SEMI_PRODUCTS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { refetch: getMaterialUnit, data: material_units } = useQuery(
    GET_MATERIAL_UNITS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { refetch: getSupplier, data: material_suppliers } = useQuery(
    GET_MATERIAL_SUPPLIERS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const { refetch: getMaterialItemCategory, data: material_item_categories } =
    useQuery(GET_MATERIAL_ITEM_CATEGORIES, {
      fetchPolicy: "no-cache",
    });

  const [createMaterialUnit] = useMutation(CREATE_MATERIAL_UNIT);
  const [createMaterialSupplier] = useMutation(CREATE_MATERIAL_SUPPLIER);
  const [createMaterialItem] = useMutation(CREATE_MATERIAL_ITEM_SEMI);
  const [deleteMaterialItem] = useMutation(DELETE_MATERIAL_ITEM);
  const [deleteMaterialItemSemi] = useMutation(DELETE_MATERIAL_ITEM_SEMI);
  const [updateMaterialItem] = useMutation(UPDATE_MATERIAL_ITEM_MENU);
  const [createMaterialItemCategory] = useMutation(
    CREATE_MATERIAL_ITEM_CATEGORY
  );

  const refetchData = async () => {
    getMaterialItemForMenu();
    getMaterialItem({ variables: varMaterrialItem });
    getSemiProduct();
    getMaterialUnit();
    getSupplier();
    getMaterialItemCategory();
  };

  const edit = (a: any) => {
    const id = a.target.closest(".e-row").children[1].innerHTML;
    const selected_mat_item =
      material_items &&
      material_items.materialItems &&
      material_items.materialItems.find((d: any) => {
        return d.ID == id;
      });

    setSelectedItem(selected_mat_item);
    setOpenModal(true);
  };

  const commands: CommandModel[] = [
    {
      type: "None",
      buttonOption: {
        cssClass: "e-flat",
        iconCss: "e-edit e-icons",
        click: (e) => {
          edit(e);
        },
      },
    },
    {
      type: "Save",
      buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
    },
    {
      type: "Cancel",
      buttonOption: { cssClass: "e-flat", iconCss: "e-cancel-icon e-icons" },
    },
  ];

  const actionComplete = async (args: any) => {
    if (args.requestType === "add") {
      // setId(id + 1)
    } else if (args.requestType === "delete") {
      const ID = args && args.data && args.data[0] && args.data[0].ID;
      await deleteMaterialItemSemi({
        variables: {
          id: ID,
        },
      });
      await deleteMaterialItem({
        variables: {
          id: ID,
        },
      });
    }

    if (
      args.requestType === "save" &&
      (args.action === "add" || args.action === "edit")
    ) {
      const data = args.data;
      const amount_unit = data["amount_unit"]["name"].trim();
      const pack_unit = data["pack_unit"]["name"].trim();
      const material_supplier = data["material_supplier"]["name"].trim();
      const material_item_category =
        data["material_item_category"]["name"].trim();
      let amount_unit_id = 0;
      let pack_unit_id = 0;
      let material_supplier_id = 0;
      let material_item_category_id = 0;

      if (
        !(
          material_units &&
          material_units.materialUnits.find((d: any, i: any) => {
            if (d.name === amount_unit) {
              amount_unit_id = d.id;
            }
            return d.name === amount_unit;
          })
        )
      ) {
        const result = await createMaterialUnit({
          variables: {
            name: amount_unit,
          },
        });
        amount_unit_id =
          result &&
          result.data &&
          result.data.createOnematerial_unit &&
          result.data.createOnematerial_unit.id;
      }

      if (
        !(
          material_units &&
          material_units.materialUnits.find((d: any, i: any) => {
            if (d.name === pack_unit) {
              pack_unit_id = d.id;
            }
            return d.name === pack_unit;
          })
        ) &&
        pack_unit != amount_unit
      ) {
        const result = await createMaterialUnit({
          variables: {
            name: pack_unit,
          },
        });
        pack_unit_id =
          result &&
          result.data &&
          result.data.createOnematerial_unit &&
          result.data.createOnematerial_unit.id;
      }

      if (
        !(
          material_suppliers &&
          material_suppliers.materialSuppliers.find((d: any, i: any) => {
            if (d.name === material_supplier) {
              material_supplier_id = d.id;
            }
            return d.name === material_supplier;
          })
        )
      ) {
        const result = await createMaterialSupplier({
          variables: {
            name: material_supplier,
          },
        });
        material_supplier_id =
          result &&
          result.data &&
          result.data.createOnematerial_supplier &&
          result.data.createOnematerial_supplier.id;
      }

      if (
        !material_item_categories?.materialItemCategories.find(
          (d: any, i: any) => {
            if (d.name === material_item_category) {
              material_item_category_id = d.id;
            }
            return d.name === material_item_category;
          }
        )
      ) {
        const result = await createMaterialItemCategory({
          variables: {
            name: material_item_category,
          },
        });
        material_item_category_id =
          result &&
          result.data &&
          result.data.createOnematerial_item_category &&
          result.data.createOnematerial_item_category.id;
      }

      if (args.action === "add") {
        const create_material_item_result = await createMaterialItem({
          variables: {
            name: data["name"],
            price: data["price"],
            amount: data["amount"],
            yield: data["yield"],
            material_item_type: "ITEM",
            pack_unit_id: pack_unit_id,
            amount_unit_id: amount_unit_id,
            material_item_category_id: material_item_category_id,
            material_supplier_id: material_supplier_id,
          },
        });

        console.log(create_material_item_result);
      } else {
        const update_material_item_result = await updateMaterialItem({
          variables: {
            id: data["id"],
            name: data["name"],
            price: data["price"],
            amount: data["amount"],
            yield: data["yield"],
            material_item_type: "ITEM",
            pack_unit_id: pack_unit_id,
            amount_unit_id: amount_unit_id,
            material_item_category_id: material_item_category_id,
            material_supplier_id: material_supplier_id,
          },
        });

        console.log(update_material_item_result);
      }
    }

    if (["add", "delete", "save"].includes(args.requestType)) refetchData();
  };

  const materialItemDataSource = material_items_items?.materialItems?.map(
    (data) => data.name
  );

  const childGridOptions: GridModel = {
    columns: [
      { field: "ID", headerText: "ID", visible: false },
      {
        field: "item_id",
        headerText: "item_id",
        visible: false,
        isPrimaryKey: true,
      },
      {
        field: "material_ingredient.name",
        headerText: "ส่วนผสม",
        edit: autocomplete(materialItemDataSource, false),
        validationRules: requiredValidation,
      },
      {
        field: "material_item_amount",
        headerText: "จำนวน",
        editType: "numericedit",
        valueAccessor: (field: string, data: any, column: ColumnModel) => {
          return (
            data["material_item_amount"] +
            " " +
            (data &&
            data["material_ingredient"] &&
            data["material_ingredient"]["amount_unit"] &&
            data["material_ingredient"]["amount_unit"]["name"]
              ? data["material_ingredient"]["amount_unit"]["name"]
              : "หน่วย")
          );
        },
        validationRules: requiredValidation,
      },
      {
        headerText: "ราคาขาย/ออเดอร์",
        allowEditing: false,
        customAttributes: customAttributes,
        valueAccessor: (field: string, data: any, column: ColumnModel) => {
          return data &&
            data["material_ingredient"] &&
            data["material_ingredient"]["amount_unit"]
            ? Math.round(
                (data["material_ingredient"]["price"] /
                  data["material_ingredient"]["amount"] /
                  (data["material_ingredient"]["yield"] / 100) +
                  Number.EPSILON) *
                  100
              ) /
                100 +
                " ฿ / " +
                (data["material_ingredient"]["amount_unit"]["name"]
                  ? data["material_ingredient"]["amount_unit"]["name"]
                  : "หน่วย")
            : "";
        },
      },
      {
        headerText: "ราคา",
        allowEditing: false,
        customAttributes: customAttributes,
        valueAccessor: (field: string, data: any, column: ColumnModel) => {
          return data &&
            data["material_ingredient"] &&
            data["material_ingredient"]["amount_unit"]
            ? Math.round(
                ((data["material_ingredient"]["price"] /
                  data["material_ingredient"]["amount"] /
                  data["material_ingredient"]["yield"]) *
                  100 *
                  data["material_item_amount"] +
                  Number.EPSILON) *
                  100
              ) /
                100 +
                " ฿"
            : "";
        },
      },
    ],
    dataSource:
      material_semi_products && material_semi_products.materialSemiProducts,
    queryString: "ID",
  };

  const Calculator = {
    "ราคาขาย/order": (data: any) => {
      return data.price;
    },
    "ต้นทุน/หน่วย": (data: any) => {
      let cost = 0;
      if (data.cost) {
        cost = data.cost;
      } else {
        let price = 0;
        data &&
          data.material_semi_product &&
          data.material_semi_product.forEach((element: any) => {
            price +=
              element && element.material_ingredient
                ? Math.round(
                    (element.material_item_amount *
                      (Math.round(
                        (element.material_ingredient.price /
                          element.material_ingredient.amount /
                          (element.material_ingredient.yield / 100) +
                          Number.EPSILON) *
                          100
                      ) /
                        100) +
                      Number.EPSILON) *
                      100
                  ) / 100
                : 0;
          });
        cost = data.amount_per_pack
          ? formatNumber(price / (data.amount / data.amount_per_pack))
          : 0;
      }
      return cost;
    },
    ต้นทุนต่อOrder: (data: any) => {
      let cost = 0;
      if (data.cost) {
        cost = data.cost;
      } else {
        let price = 0;
        data?.material_semi_product?.forEach((element: any) => {
          price += element?.material_ingredient
            ? Math.round(
                (element.material_item_amount *
                  (Math.round(
                    (element.material_ingredient.price /
                      element.material_ingredient.amount /
                      (element.material_ingredient.yield / 100) +
                      Number.EPSILON) *
                      100
                  ) /
                    100) +
                  Number.EPSILON) *
                  100
              ) / 100
            : 0;
        });
        cost = data.amount_per_pack
          ? formatNumber(price / (data.amount / data.amount_per_pack))
          : 0;
      }
      return cost * (data?.piece_per_menu || 1);
    },
    กำไร: (data: any) => {
      const benefits =
        Calculator["ราคาขาย/order"](data) - Calculator["ต้นทุนต่อOrder"](data);
      const benefitPercents =
        (benefits / Calculator["ต้นทุนต่อOrder"](data)) * 100;

      return {
        benefits,
        benefitPercents,
      };
    },
  };
  const PRICING_WIDTH = 150;

  return (
    <div>
      <CommonHeader page="material" value="menu" />
      <div className="p-4 pb-2">
        {MenuCategories.map((cat) => (
          <Button
            variant={
              `${selectedCategory === cat ? "contained" : "outlined"}` as
                | "text"
                | "outlined"
                | "contained"
            }
            key={cat}
            color="primary"
            className="focus:outline-none"
            style={{ marginRight: "0.75rem" }}
            onClick={() => {
              setSelectedCategory(cat);
              if (cat === "ทั้งหมด") getMaterialItem();
              else
                getMaterialItem({
                  variables: {
                    menu_category: {
                      name: { equals: cat },
                    },
                  },
                });
            }}
          >
            {loadingMaterialItem && selectedCategory === cat
              ? "loading..."
              : cat}
          </Button>
        ))}
      </div>
      <Button
        color="primary"
        onClick={() => {
          setSelectedItem(null);
          setOpenModal(true);
        }}
      >
        <AddIcon className="pr-1 my-2" /> เพิ่มเมนู
      </Button>
      <AddEditModal
        open={openModal}
        setOpen={setOpenModal}
        refetch={refetchData}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        material_units={material_units?.materialUnits || []}
        material_items_items={material_items_items?.materialItems || []}
      />
      <GridComponent
        dataSource={material_items?.materialItems.map((d) => ({
          ...d,
          cost_unit: Calculator["ต้นทุน/หน่วย"](d),
          cost_percent:
            (Calculator.ต้นทุนต่อOrder(d) / Calculator["ราคาขาย/order"](d)) *
            100,
          cost_order: Calculator.ต้นทุนต่อOrder(d),
          profit: Calculator.กำไร(d),
        }))}
        editSettings={{
          allowAdding: true,
          allowDeleting: true,
          allowEditing: true,
          allowEditOnDblClick: false,
        }}
        toolbar={["Delete", "Search"]}
        actionComplete={actionComplete.bind(this)}
        allowFiltering={true}
        allowSorting={true}
        allowPaging={true}
        pageSettings={{ pageSize: 20 }}
        searchSettings={searchSettings}
        filterSettings={filterSettings}
        childGrid={childGridOptions}
      >
        <ColumnsDirective>
          <ColumnDirective
            visible={false}
            field="id"
            headerText="ID"
            valueAccessor={(field, data: any, column) => {
              return data["ID"]
                ? data["ID"]
                : material_items &&
                  material_items.materialItems &&
                  material_items.materialItems[0]
                ? material_items.materialItems[0].ID + 1
                : 1;
            }}
            isPrimaryKey={true}
            validationRules={requiredValidation}
          />
          <ColumnDirective
            field="name"
            headerText="เมนู"
            width={200}
            validationRules={requiredValidation}
          />
          {/* <ColumnDirective
            field="amount"
            headerText="ปริมาณที่ได้"
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return data["amount"]
                ? data["amount"] + " " + data["amount_unit"]["name"]
                : "-";
            }}
          />
          <ColumnDirective
            field="amount"
            headerText="อัตราส่วน/ชิ้น"
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return data["amount_per_pack"]
                ? data["amount_per_pack"] +
                    " " +
                    data["amount_unit"]["name"] +
                    " / " +
                    data["pack_unit"]["name"]
                : "-";
            }}
          />
          <ColumnDirective
            field="amount"
            headerText="จำนวนชิ้น/เมนู"
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return data["piece_per_menu"] ? data["piece_per_menu"] : "-";
            }}
          /> */}
          {/* <ColumnDirective
            field="amount"
            headerText="จำนวนชิ้นต่อสูตร"
            width={PRICING_WIDTH}
            customAttributes={customAttributes}
            editType="numericedit"
            allowFiltering={false}
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return data.amount_per_pack
                ? formatNumber(data.amount / data.amount_per_pack) +
                    " " +
                    data.pack_unit.name
                : "-";
            }}
          /> */}
          {/* <ColumnDirective
            headerText="ต้นทุนต่อสูตร"
            width={PRICING_WIDTH}
            editType="numericedit"
            valueAccessor={(field, data: any, column) => {
              let price = 0;
              data &&
                data.material_semi_product &&
                data.material_semi_product.forEach((element: any) => {
                  price +=
                    element && element.material_ingredient
                      ? Math.round(
                          (element.material_item_amount *
                            (Math.round(
                              (element.material_ingredient.price /
                                element.material_ingredient.amount /
                                (element.material_ingredient.yield / 100) +
                                Number.EPSILON) *
                                100
                            ) /
                              100) +
                            Number.EPSILON) *
                            100
                        ) / 100
                      : 0;
                });
              return `${price} ฿ (${formatNumber(
                (price / data.price) * 100
              )}%)`;
            }}
            customAttributes={customAttributes}
          /> */}
          <ColumnDirective
            field="cost_unit"
            headerText="ต้นทุน/หน่วย"
            width={PRICING_WIDTH}
            editType="numericedit"
            valueAccessor={(field, data: any, column) => {
              return (
                data.cost_unit + " ฿"
                //  / " +
                // (data && data.pack_unit
                //   ? data.pack_unit.name
                //   : data.amount_unit.name)
              );
            }}
            customAttributes={customAttributes}
          />
          <ColumnDirective
            field="cost_order"
            headerText="ต้นทุน/ออเดอร์"
            width={PRICING_WIDTH}
            editType="numericedit"
            valueAccessor={(field, data: any, column) => {
              return (
                formatNumber(data.cost_order) + " ฿"
                // / " +
                // (data.pack_unit.name || data.amount_unit.name)
              );
            }}
            customAttributes={customAttributes}
          />
          <ColumnDirective
            field="price"
            headerText="ราคาขาย/ออเดอร์"
            width={PRICING_WIDTH}
            customAttributes={customAttributes}
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return (
                formatNumber(Calculator["ราคาขาย/order"](data)) + " ฿"
                // " ฿ / " +
                // (data && data.pack_unit
                //   ? data.pack_unit.name
                //   : data.amount_unit.name)
              );
            }}
          />
          <ColumnDirective
            field="cost_percent"
            headerText="ต้นทุน/ราคาขาย"
            width={PRICING_WIDTH}
            customAttributes={customAttributes}
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              return formatNumber(data.cost_percent) + "%";
            }}
          />
          <ColumnDirective
            field="profit.benefits"
            headerText="กำไร"
            width={PRICING_WIDTH}
            customAttributes={customAttributes}
            editType="numericedit"
            validationRules={minOneValidation}
            valueAccessor={(field, data: any, column) => {
              const { benefits, benefitPercents } = data.profit;
              return `${formatNumber(benefits)} ฿ (${formatNumber(
                benefitPercents
              )}%)`;
            }}
          />
          <ColumnDirective
            commands={commands}
            headerText="แก้"
            headerTextAlign="Center"
            textAlign="Center"
            width="6%"
          />
        </ColumnsDirective>
        <Inject
          services={[
            Edit,
            CommandColumn,
            Toolbar,
            Page,
            Search,
            Filter,
            Sort,
            DetailRow,
          ]}
        />
      </GridComponent>
    </div>
  );
};

export default MaterialMenuPage;

interface AddEditModalProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  refetch: () => void;
  selectedItem: MaterialItem | undefined | null;
  setSelectedItem: (item: MaterialItem | undefined | null) => void;
  material_items_items: MaterialItem[] | undefined | null;
  material_units: MaterialUnit[] | undefined | null;
}

const AddEditModal = ({
  open,
  setOpen,
  refetch,
  selectedItem,
  setSelectedItem,
  material_units,
  material_items_items,
}: AddEditModalProps) => {
  const {
    refetch: refetchSingleMaterialItem,
    data: material_item,
    loading,
  } = useQuery<{
    materialItem: MaterialItem;
  }>(GET_ONE_MATERIAL_ITEM, {
    fetchPolicy: "no-cache",
    variables: { id: selectedItem?.ID || -1 },
  });

  const [createMaterialUnit] = useMutation(CREATE_MATERIAL_UNIT);
  const [createMaterialSemiProduct] = useMutation(CREATE_MATERIAL_SEMIPRODUCT);
  const [createMaterialItem, { loading: loadingCreateMaterialItem }] =
    useMutation(CREATE_MATERIAL_ITEM_SEMI);
  const [updateMaterialItem, { loading: loadingUpdateMaterialItem }] =
    useMutation(UPDATE_MATERIAL_ITEM_MENU);
  const [deleteMaterialSemiProduct] = useMutation(DELETE_MATERIAL_SEMI_PRODUCT);
  const [updateMaterialSemiProduct] = useMutation(UPDATE_MATERIAL_SEMIPRODUCT);

  useEffect(() => {
    if (open) {
      if (!selectedItem) {
        setName(null);
        setAmount(null);
        setAmount_per_pack(null);
        setAmount_unit_name(null);
        setPack_unit_name(null);
        setPrice(null);
        setNote(null);
        setCost(null);
        setPiecePerMenu(null);
      }

      console.log("refetchsingle");
      refetchSingleMaterialItem({ variables: { id: selectedItem?.ID || -1 } });
    } else refetch();
  }, [open, selectedItem]);

  const modalToolbarOptions: ToolbarItems[] = [
    "Add",
    "Edit",
    "Delete",
    "Update",
    "Search",
  ];
  const modalEditOptions: EditSettingsModel = {
    allowAdding: true,
    allowDeleting: true,
    allowEditing: true,
    allowEditOnDblClick: false,
  };

  const [name, setName]: any = useState(null);
  const [amount, setAmount]: any = useState(null);
  const [amount_per_pack, setAmount_per_pack]: any = useState(null);
  const [amount_unit_name, setAmount_unit_name]: any = useState(null);
  const [pack_unit_name, setPack_unit_name]: any = useState(null);
  const [note, setNote]: any = useState(null);
  const [price, setPrice]: any = useState(null);
  const [cost, setCost]: any = useState(null);
  const [category, setCategory]: any = useState("อาหารอื่นๆ");
  const [piece_per_menu, setPiecePerMenu] = useState<string>("0");

  useEffect(() => {
    if (open) {
      console.log("refetchsingle");
      refetchSingleMaterialItem({ variables: { id: selectedItem?.ID || -1 } });
    } else refetch();
  }, [open, selectedItem]);

  useEffect(() => {
    if (!selectedItem) return;
    console.log("selectedItem", selectedItem);
    setName(selectedItem.name);
    setAmount(selectedItem.amount);
    setAmount_per_pack(selectedItem.amount_per_pack);
    setAmount_unit_name(
      selectedItem.amount_unit && selectedItem.amount_unit.name
    );
    setPack_unit_name(selectedItem.pack_unit && selectedItem.pack_unit.name);
    setPrice(selectedItem.price);
    setNote(selectedItem.note);
    setCost(selectedItem.cost);
    setPiecePerMenu((selectedItem.piece_per_menu || "").toString());
    setCategory(selectedItem.material_menu_category?.name);
  }, [material_item]);

  const createItem = async () => {
    let amount_unit_id = 1;
    let pack_unit_id = 1;

    if (
      amount_unit_name &&
      !material_units.find((d: any, i: any) => {
        if (d.name === amount_unit_name) {
          amount_unit_id = d.id;
        }
        return d.name === amount_unit_name;
      })
    ) {
      const result = await createMaterialUnit({
        variables: {
          name: amount_unit_name,
        },
      });
      amount_unit_id =
        result &&
        result.data &&
        result.data.createOnematerial_unit &&
        result.data.createOnematerial_unit.id;
    }

    if (
      pack_unit_name &&
      !material_units.find((d: any, i: any) => {
        if (d.name === pack_unit_name) {
          pack_unit_id = d.id;
        }
        return d.name === pack_unit_name;
      }) &&
      pack_unit_name != amount_unit_name
    ) {
      const result = await createMaterialUnit({
        variables: {
          name: pack_unit_name,
        },
      });
      pack_unit_id =
        result &&
        result.data &&
        result.data.createOnematerial_unit &&
        result.data.createOnematerial_unit.id;
    }

    if (selectedItem) {
      console.log("costja", cost, price);
      const update_material_item_result = await updateMaterialItem({
        variables: {
          id: selectedItem.ID,
          name: name,
          amount: parseFloat(amount),
          amount_per_pack: parseFloat(amount_per_pack),
          ...(category ? { menu_category: category } : {}),
          piece_per_menu: parseInt(piece_per_menu ? piece_per_menu : "0"),
          pack_unit_id: pack_unit_id,
          amount_unit_id: amount_unit_id,
          note: note,
          price: parseFloat(price),
          cost: parseFloat(cost),
        },
      });
      setSelectedItem(update_material_item_result.data.updateOnematerial_item);
    } else {
      console.log("costjaa", cost, price);
      const create_material_item_result = await createMaterialItem({
        variables: {
          name: name,
          amount: parseFloat(amount),
          amount_per_pack: parseFloat(amount_per_pack),
          material_item_type: "MENU",
          pack_unit_id: pack_unit_id,
          piece_per_menu: parseInt(piece_per_menu ? piece_per_menu : "0"),
          amount_unit_id: amount_unit_id,
          note: note,
          price: parseFloat(price),
          cost: parseFloat(cost),
        },
      });
      setSelectedItem(create_material_item_result.data.createOnematerial_item);
    }
    refetchSingleMaterialItem();
    refetch();
  };

  const modalActionComplete = async (args: any) => {
    if (args.requestType === "delete") {
      const ID = args?.data?.[0]?.material_ingredient?.id;
      await deleteMaterialSemiProduct({
        variables: {
          material_semi_product_id: selectedItem.ID,
          material_ingredient_id: ID,
        },
      });
    }

    if (
      args.requestType === "save" &&
      (args.action === "add" || args.action === "edit")
    ) {
      const data = args.data;
      const material_item_amount = data.amount;
      const name = data.material_ingredient.name;
      let material_ingredient_id = 0;

      material_items_items.find((d: any, i: any) => {
        if (d.name === name) {
          material_ingredient_id = d.ID;
        }
        return d.name === pack_unit_name;
      });

      if (args.action === "add") {
        console.log({
          material_semi_product_id: selectedItem.ID,
          material_ingredient_id: material_ingredient_id,
          material_item_amount: material_item_amount,
        });
        await createMaterialSemiProduct({
          variables: {
            material_semi_product_id: selectedItem.ID,
            material_ingredient_id: material_ingredient_id,
            material_item_amount: material_item_amount,
          },
        });
      } else if (args.action === "edit") {
        console.log("editing");
        const old_ingredient_id = args.rowData.material_ingredient.id;
        // same material = update
        if (old_ingredient_id === material_ingredient_id) {
          console.log("updating");
          await updateMaterialSemiProduct({
            variables: {
              semi_product_id: selectedItem?.ID,
              ingredient_id: material_ingredient_id,
              material_item_amount: material_item_amount,
            },
          });
        }
        // different mat = delete and recreate
        else {
          await deleteMaterialSemiProduct({
            variables: {
              material_semi_product_id: selectedItem?.ID,
              material_ingredient_id: old_ingredient_id,
            },
          });
          //create new one
          await createMaterialSemiProduct({
            variables: {
              material_semi_product_id: selectedItem?.ID,
              material_ingredient_id: material_ingredient_id,
              material_item_amount: material_item_amount,
            },
          });
        }
      }
    }
    if (
      args.requestType === "delete" ||
      (args.requestType === "save" &&
        (args.action === "add" || args.action === "edit"))
    )
      refetchSingleMaterialItem();
  };

  const materialItemDataSource = material_items_items?.map((data) => data.name);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        className="w-3/4 bg-white px-8 py-6 absolute border-gray-300 overflow-y-scroll"
        style={{
          minHeight: "95%",
          maxHeight: "95%",
          borderRadius: "0.5rem",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        {loading ? (
          <div>loading...</div>
        ) : (
          <>
            <div className="flex items-center pb-6">
              {selectedItem ? `แก้ไขเมนู ${selectedItem.name}` : "เพิ่มเมนู"}
            </div>
            <div className="flex w-full flex-wrap">
              <div className="mb-6 w-5/12">
                <TextField
                  className="w-full"
                  required
                  label="ชื่อเมนู"
                  placeholder="เบสหมู"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  defaultValue={name}
                  onChange={(e: any) => {
                    setName(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <TextField
                  className="w-full"
                  label="ปริมาณ"
                  placeholder="5000"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  defaultValue={amount}
                  onChange={(e: any) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <Autocomplete
                  freeSolo
                  options={material_units.map((option: any) => option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="หน่วยย่อย"
                      placeholder="กรัม"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  defaultValue={
                    selectedItem?.amount_unit?.name || amount_unit_name
                  }
                  onInputChange={(e: any) => {
                    if (e && e.target && e.target.value) {
                      setAmount_unit_name(e.target.value);
                    }
                  }}
                  onChange={(e: any, data: any) => {
                    setAmount_unit_name(data);
                  }}
                />
              </div>
              <div className="w-1/12"></div>

              <div className="mb-6 w-5/12">
                <TextField
                  className="w-full"
                  label="อัตราส่วนต่อชิ้น"
                  placeholder="15"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  defaultValue={amount_per_pack}
                  onChange={(e: any) => {
                    setAmount_per_pack(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <Autocomplete
                  freeSolo
                  options={material_units.map((option: any) => option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="หน่วยชิ้น (ควรใส่)"
                      placeholder="กรัม"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  defaultValue={selectedItem?.pack_unit?.name || pack_unit_name}
                  onInputChange={(e: any) => {
                    if (e && e.target && e.target.value) {
                      setPack_unit_name(e.target.value);
                    }
                  }}
                  onChange={(e: any, data: any) => {
                    setPack_unit_name(data);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <TextField
                  label="จำนวนชิ้นต่อเมนู"
                  placeholder="10"
                  variant="outlined"
                  value={piece_per_menu}
                  onChange={(e) => {
                    setPiecePerMenu(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className="mb-6 w-5/12">
                <TextField
                  className="w-full"
                  label="ต้นทุนต่อชิ้น (ต้องใส่ถ้ารับจาก supplier มาขาย)"
                  placeholder="10"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  defaultValue={cost}
                  onChange={(e: any) => {
                    setCost(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <TextField
                  className="w-full"
                  label="ราคาขายต่อออเดอร์"
                  required
                  placeholder="30"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  name="xxx"
                  defaultValue={price}
                  onChange={(e: any) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>
              <div className="w-1/12"></div>
              <div className="mb-6 w-2/12">
                <FormControl>
                  <Select
                    color="primary"
                    variant="outlined"
                    placeholder="เลือกประเภท"
                    value={category || "-"}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  >
                    <MenuItem disabled value="-">
                      ไม่ได้เลือก
                    </MenuItem>
                    {MenuCategories.slice(1).map((cat) => (
                      <MenuItem key={cat} value={cat}>
                        {cat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="mb-6 w-full border border-black-500 outline-none">
                <TextareaAutosize
                  className="w-full h-72 p-4"
                  placeholder="คลิกเพื่อใส่ note"
                  defaultValue={note}
                  onChange={(e: any) => {
                    setNote(e.target.value);
                  }}
                />
                {/* <TextField className="w-full" label="โน้ต" placeholder="note" InputLabelProps={{ shrink: true, }} variant="outlined" name="xxx" defaultValue={note} onChange={(e: any) => { setNote(e.target.value) }} /> */}
              </div>

              <div className="mb-4">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    loadingCreateMaterialItem || loadingUpdateMaterialItem ? (
                      <></>
                    ) : (
                      <DoneIcon />
                    )
                  }
                  onClick={createItem}
                >
                  {` ${
                    loadingCreateMaterialItem || loadingUpdateMaterialItem
                      ? "กำลังบันทึก..."
                      : selectedItem
                      ? "บันทึก"
                      : "สร้าง"
                  } `}
                </Button>
              </div>
              {selectedItem && (
                <GridComponent
                  dataSource={
                    material_item?.materialItem?.material_semi_product
                  }
                  editSettings={modalEditOptions}
                  toolbar={modalToolbarOptions}
                  actionComplete={modalActionComplete.bind(this)}
                  allowFiltering={true}
                  allowSorting={true}
                  allowPaging={true}
                  pageSettings={{ pageSize: 20 }}
                  searchSettings={searchSettings}
                  filterSettings={filterSettings}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      visible={false}
                      field="ID"
                      headerText="ID"
                      valueAccessor={(field, data: any, column) => {
                        return data.material_ingredient &&
                          data.material_ingredient.id
                          ? data.material_ingredient.id
                          : -1;
                      }}
                      isPrimaryKey={true}
                      validationRules={requiredValidation}
                    />
                    <ColumnDirective
                      field="material_ingredient.name"
                      headerText="ส่วนผสม"
                      edit={autocomplete(materialItemDataSource, false)}
                    />
                    <ColumnDirective
                      field="amount"
                      headerText="จำนวน"
                      editType="numericedit"
                      validationRules={requiredValidation}
                      valueAccessor={(field, data: any, column) => {
                        return (
                          data["material_item_amount"] +
                          " " +
                          (data &&
                          data["material_ingredient"] &&
                          data["material_ingredient"]["amount_unit"] &&
                          data["material_ingredient"]["amount_unit"]["name"]
                            ? data["material_ingredient"]["amount_unit"]["name"]
                            : "หน่วย")
                        );
                      }}
                    />
                    <ColumnDirective
                      headerText="ราคาขาย/ออเดอร์"
                      allowEditing={false}
                      editType="numericedit"
                      customAttributes={customAttributes}
                      valueAccessor={(field, data: any, column) => {
                        return data &&
                          data["material_ingredient"] &&
                          data["material_ingredient"]["amount_unit"]
                          ? Math.round(
                              (data["material_ingredient"]["price"] /
                                data["material_ingredient"]["amount"] /
                                (data["material_ingredient"]["yield"] / 100) +
                                Number.EPSILON) *
                                100
                            ) /
                              100 +
                              " ฿ / " +
                              (data["material_ingredient"]["amount_unit"][
                                "name"
                              ]
                                ? data["material_ingredient"]["amount_unit"][
                                    "name"
                                  ]
                                : "หน่วย")
                          : "";
                      }}
                    />
                    <ColumnDirective
                      headerText="ราคา"
                      allowEditing={false}
                      customAttributes={customAttributes}
                      editType="numericedit"
                      valueAccessor={(field, data: any, column) => {
                        return data &&
                          data["material_ingredient"] &&
                          data["material_ingredient"]["amount_unit"]
                          ? Math.round(
                              ((data["material_ingredient"]["price"] /
                                data["material_ingredient"]["amount"] /
                                data["material_ingredient"]["yield"]) *
                                100 *
                                data["material_item_amount"] +
                                Number.EPSILON) *
                                100
                            ) /
                              100 +
                              " ฿"
                          : "";
                      }}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Edit,
                      CommandColumn,
                      Toolbar,
                      Page,
                      Search,
                      Filter,
                      Sort,
                      DetailRow,
                    ]}
                  />
                </GridComponent>
              )}
            </div>
            {/* <input type="submit" onClick={onSubmit} /> */}
          </>
        )}
      </div>
    </Modal>
  );
};
