import axios from "axios";
import { BASE_URL } from "../../utils/graphql";

export const uploadImg = async (options: any) => {
  const { onSuccess, onError, file, onProgress } = options;
  const fmData = new FormData();
  const config = {
    headers: { "content-type": "multipart/form-data" },
  };
  fmData.append("myFile", file);
  try {
    const res = await axios.post(BASE_URL + "/uploadfile", fmData, config);
    onSuccess(res, file);
    console.log("server res: ", res);
  } catch (err) {
    console.log("Eroor: ", err);
    const error = new Error("Some error");
    onError(err);
  }
};
